<template>
<button class="d-none" id="BtnSearch" @click="Search()"></button>
  <div class="row flex-div">
    <div class="col-md-12 flex-div pb-3 pt-3">
      <div class="card flex-div border-0 main-shadow">
        <div class="card-header bg-white">
          <div class="row">
            <div class="col-lg-10 col-12">
              <div class="form-inline">
              <a
                @click="Create()"
                data-toggle="modal"
                data-target="#modalSave"
                class="
                  btn btn-success
                  mx-1
                  mb-1
                  float-lg-right float-none
                  text-white
                  Loader
                "
                ><i class="icon-plus"></i>{{ language.Create }} {{ language.Employee }}</a
              >
            </div>
            </div>
          </div>
        </div>
        <div id="pat" class="flex-div">
          <Operator-list :Operators="list" v-if="HasList"></Operator-list>
          <div class="card-footer bg-white">
            <nav aria-label="Page navigation" class="float-left">
              <ul class="pagination pagination-sm">
                <li v-if="Pageid > 1" class="page-item">
                  <a
                    aria-label="Previous"
                    @click="PageChev(0)"
                    class="page-link"
                    ><span aria-hidden="true"
                      ><i class="icon-chevron-right m-0"></i></span
                  ></a>
                </li>

                <li
                  v-for="index in PageCount"
                  :key="index"
                  :class="{ active: Pageid == index }"
                  class="page-item"
                >
                  <a @click="Page(index)" class="page-link"
                    >{{ index }}<span class="sr-only"></span
                  ></a>
                </li>

                <li v-if="PageCount + 1 > Pageid" class="page-item">
                  <a aria-label="Next" @click="PageChev(1)" class="page-link"
                    ><span aria-hidden="true"
                      ><i class="icon-chevron-left m-0"></i></span
                  ></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import OperatorList from "./OperatorList.vue";
import UserCreateOrEdit from "../user/UserCreateOrEdit.vue";
import { openModal } from "jenesius-vue-modal";
import axios from "axios";
import {
  Loading,
  TitleBar,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";

export default {
  components: {
    OperatorList,
  },
  data() {
    return {
      PageCount: 1,
      Pageid: 1,
      list: [],
      HasList: false
    };
  },
  mounted() {
    TitleBar(this.language.Employee);
    this.LoadData();
  },
  methods: {
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get(`/User/GetEmployeeList`, {
            params: {
              PageId: this.Pageid
            },
          })
          .then((res) => {
            if (res.data.isSuccess) {
              if (res.data.result.list.length == 0) {
                this.HasList = false;
                Loading(0);
              } else {
                this.HasList = true;
                this.PageCount = res.data.result.pageCount;
                this.list = res.data.result.list;
                Loading(0);
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
    Search() {
      this.LoadData();
    },
     Create() {
      openModal(UserCreateOrEdit, {
        idProp: 0,
        TelNuProp: "",
        EmailProp: "",
        PassportNuProp: "",
        PasswordProp: "",
        GenderProp: 0,
        WhatsappAccountProp: "",
        ResidenceProp: "",
        IsProviderProp: false,
        IsEmployeeProp: true,
      });
    },

    Page(id) {
      this.Pageid = id;
      this.LoadData();
    },
    PageChev(i) {
      if (i === 1) {
        this.Pageid++;
      } else if (i === 0) {
        this.Pageid = this.Pageid - 1;
      }
      this.LoadData();
    },
  },
};
</script>
  