<template>
  <div class="modal show">
    <div
      class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
      role="document"
    >
      <div id="modal-content" class="modal-content">
        <div class="modal-header">
          <h6 v-if="id == 0 || id == null" class="modal-title">
            {{ language.Create }}
          </h6>
          <h6 v-if="id != 0" class="modal-title">{{ language.Edit }}</h6>
          <button
            @click="closeModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-row">
              <div class="col-12" v-for="item in SignupActlist" :key="item.id">
                <!-- TelNuInput -->
                <div class="row" v-if="item.name == 'TelNu'">
                  <label class="col-lg-3 col-form-label">
                    {{ language.TelNu }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="User.TelNu"
                      :placeholder="language.TelNu"
                    />
                    <small v-if="item.isRequired" class="text-danger">{{
                      language.required
                    }}</small>

                    <small v-if="item.isUserName" class="text-danger">
                      - {{ language.Username }}</small
                    >
                  </div>
                </div>

                <!-- EmailInput -->
                <div class="row" v-if="item.name == 'Email'">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Email }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="User.Email"
                      :placeholder="language.Email"
                    />
                    <small v-if="item.isRequired" class="text-danger">{{
                      language.required
                    }}</small>

                    <small v-if="item.isUserName" class="text-danger">
                      - {{ language.Username }}</small
                    >
                  </div>
                </div>

                <!-- PassportNuInput -->
                <div class="row" v-if="item.name == 'PassportNu'">
                  <label class="col-lg-3 col-form-label">
                    {{ language.PassportNu }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="User.PassportNu"
                      :placeholder="language.PassportNu"
                    />
                    <small v-if="item.isRequired" class="text-danger">{{
                      language.required
                    }}</small>

                    <small v-if="item.isUserName" class="text-danger">
                      - {{ language.Username }}</small
                    >
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.FullName }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="User.FullName"
                      :placeholder="language.FullName"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Password }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      style="text-security: disc; -webkit-text-security: disc"
                      type="password"
                      class="form-control"
                      v-model="User.passwordHash"
                      :placeholder="language.Password"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Gender }}
                  </label>
                  <div class="form-group col-lg-6">
                    <select v-model="User.Gender" class="form-control">
                      <option :value="1">{{ language.Male }}</option>
                      <option :value="2">{{ language.Female }}</option>
                      <option :value="3">{{ language.Other }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.WhatsappAccount }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="User.WhatsappAccount"
                      :placeholder="language.WhatsappAccount"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.BirthdayDate }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input v-model="User.BirthdayDate" />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Residence }}
                  </label>
                  <div class="form-group col-lg-6">
                    <textarea
                      v-model="User.Residence"
                      class="form-control"
                      :placeholder="language.Residence"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Category }}
                  </label>
                  <div class="form-group col-lg-6">
                    <select v-model="Provider.categoryId" class="form-control">
                      <option
                        v-for="Category in CategoryList"
                        :key="Category.id"
                        :value="Category.id"
                      >
                        {{ Category.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.LanguageFa }} {{ language.ShopName }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="Provider.shopName"
                      :placeholder="language.ShopName"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.LanguageTr }} {{ language.ShopName }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="Provider.TrshopName"
                      :placeholder="language.ShopName"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                   {{ language.Star }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="number"
                      minlength="1"
                      maxlength="99"
                      class="form-control"
                      v-model="Provider.Star"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Quota }} (%)
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="number"
                      minlength="1"
                      maxlength="99"
                      class="form-control"
                      v-model="Provider.sitePercent"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Quota }} {{ language.ServeMenu }} (%)
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="number"
                      minlength="1"
                      maxlength="99"
                      class="form-control"
                      v-model="Provider.sitePercentMenuOrder"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Quota }} {{ language.Employee }} (%)
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="number"
                      minlength="1"
                      maxlength="99"
                      class="form-control"
                      v-model="Provider.operatorPercent"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Address }}
                  </label>
                  <div class="form-group col-lg-6">
                    <textarea
                      v-model="Provider.address"
                      :placeholder="language.Address"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.LanguageFa }} {{ language.Description }}
                  </label>
                  <div class="form-group col-lg-6">
                    <textarea
                      v-model="Provider.description"
                      :placeholder="language.Description"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.LanguageTr }} {{ language.Description }}
                  </label>
                  <div class="form-group col-lg-6">
                    <textarea
                      v-model="Provider.Trdescription"
                      :placeholder="language.Description"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="submitUser()"
            v-if="id == 0"
            class="btn btn-sm btn-success"
          >
            {{ language.Create }}
          </button>
          <button
            @click="submitUser()"
            v-if="id != 0"
            class="btn btn-sm btn-success"
          >
            {{ language.Create }}
          </button>
          <button
            @click="closeModal()"
            type="button"
            class="btn btn-sm btn-info"
            data-dismiss="modal"
          >
            {{ language.Close }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { closeModal } from "jenesius-vue-modal";
import useValidate from "@vuelidate/core";
//import { required } from "@vuelidate/validators";
import {
  Loading,
  CheckNetwork,
  ShowAlert,
  ReloadList,
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      v$: useValidate(),
      CategoryList: [],
      Provider: {
        id: 0,
        categoryId: "",
        shopName: "",
        address: "",
        description: "",
        location: "",
        userId: 0,
        sitePercent: 0,
        sitePercentMenuOrder: 0,
        operatorPercent: 0,
        CategoryList: [],
        TrshopName: "",
        Trdescription: "",
        Star:"",
      },
      User: {
        userName: "k",
        id: 0,
        TelNu: "",
        Email: "",
        PassportNu: "",
        FullName: "",
        passwordHash: "",
        Gender: Number,
        WhatsappAccount: "",
        Residence: "",
        BirthdayDate: null,
      },
      SignupActlist: [],
    };
  },
  // validations() {
  //   return {
  //     categoryId: { required },
  //     address: { required },
  //     userId: { required },
  //     shopName: { required },
  //   };
  // },

  props: {
    IdProp: Number,
    UserIdProp: Number,
  },
  mounted() {
    Loading(1);
    if (CheckNetwork()) {
      axios
        .get(`/SignupAct/GetList`)
        .then((res) => {
          if (res.data.isSuccess) {
            if (res.data.result.length == 0) {
              Loading(0);
            } else {
              this.SignupActlist = res.data.result;
              Loading(0);
            }
          }
        })
        .catch((err) => console.log(err));
      axios
        .get(`/Category/GetList`)
        .then((res) => {
          if (res.data.isSuccess) {
            if (res.data.result.list.length == 0) {
              this.HasList = false;
              Loading(0);
            } else {
              this.HasList = true;
              this.PageCount = res.data.result.pageCount;
              this.CategoryList = res.data.result.list;
              Loading(0);
            }
          }
        })
        .catch((err) => console.log(err));
      this.userId = this.UserIdProp;
    } else {
      Loading(0);
      ShowAlert("danger", this.language.error, this.language.Checkyourinternet);
    }
  },
  methods: {
    closeModal() {
      closeModal();
    },
    submitUser() {
      Loading(1);
      if (
        this.Provider.categoryId != null &&
        this.Provider.shopName != null &&
        this.Provider.address != null &&
        this.Provider.categoryId != 0 &&
        this.Provider.shopName != "" &&
        this.Provider.address != ""
      ) {
        if (CheckNetwork()) {
          if (this.sitePercent > 99 || this.sitePercent < 1) {
            ShowAlert(
              "danger",
              this.language.error,
              this.language.ErrorofPercent
            );
            Loading(0);
            return;
          }
          axios
            .post("/Provider/CreateFullProvider", {
              user: this.User,
              provider: this.Provider,
            })
            .then((res) => {
              if (res.data.isSuccess) {
                Loading(0);
                closeModal();
                ReloadList();
                ShowAlert("success", "✓", this.language.success);
              } else {
                Loading(0);
                if (res.data.message == "TelNu-IsRequired") {
                    ShowAlert(
                      "danger",
                      this.language.error,
                      this.language.TelNuIsRequired
                    );
                  } else if (res.data.message == "Email-IsRequired") {
                    ShowAlert(
                      "danger",
                      this.language.error,
                      this.language.EmailIsRequired
                    );
                  } else if (res.data.message == "PassportNu-IsRequired") {
                    ShowAlert(
                      "danger",
                      this.language.error,
                      this.language.PassportNuIsRequired
                    );
                  } else if (res.data.message == "Duplicate") {
                    ShowAlert(
                      "danger",
                      this.language.error,
                      this.language.UserDuplicate
                    );
                  }
                
              }
            })
            .catch((err) => console.log(err));
        }
      } else {
        Loading(0);
        ShowAlert("danger", this.language.error, this.language.ValidateForm);
      }
    },
  },
};
</script>
