import axios from "axios";
import { Loading, ShowAlert } from "../public/js/Utilities";
//•Production
axios.defaults.baseURL = 'https://service.kolayol.com/api/'
//•Test
//axios.defaults.baseURL = 'https://localhost:7248/api/'

axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + localStorage.getItem('token');


axios.interceptors.response.use((response) => response, (error) => {
    // whatever you want to do with the error

    if (error.message.includes("401")) {
        localStorage.clear();
         window.location.reload();
        return;
    }
    if (error.message.includes("Network")) {
        Loading(0);
        ShowAlert("danger", "Network Error", "");
    } else {
        Loading(0);
        ShowAlert("danger", error.message, "");
    }

});