<template>
  <div class="row flex-div">
    <div class="col-md-12 flex-div pb-3 pt-3">
      <div class="card flex-div border-0 main-shadow">
        <div class="card-header bg-white">
          <div class="row">
            <div class="col-lg-10 col-12">
              <div class="form-inline">
                <div class="form-group ml-3 mb-3">
                  <input
                    id="UserName"
                    class="form-control"
                    type="text"
                    :placeholder="language.Employee"
                    v-model="OperatorUserName"
                    @change="Search()"
                  />
                </div>
                <div class="form-group ml-3 mb-3">
                  <select v-model="IsDeposit" class="form-control">
                    <option selected :value="0">
                      {{ language.All }}
                    </option>
                    <option selected :value="1">
                      {{ language.IncreaseWallet }}
                    </option>
                    <option selected :value="2">
                      {{ language.DeductionWallet }}
                    </option>
                  </select>
                </div>
                <div class="form-group ml-3 mb-3">
                  <select v-model="walletEnum" class="form-control">
                    <option selected :value="null">
                      {{ language.All }}
                    </option>
                    <option selected :value="0">
                      {{ language.CreateOrder }}
                    </option>
                    <option :value="1">{{ language.FreindlyPack }}</option>
                    <option :value="2">{{ language.IncreaseWallet }}</option>
                    <option :value="3">{{ language.CancelOrder }}</option>
                    <option :value="4">{{ language.ServeMenu }}</option>
                  </select>
                </div>

                <div class="form-group mb-2">
                  <label class="mr-2">{{ language.DateCreate }}</label>
                  <datepicker v-model="DateCreate"></datepicker>
                </div>
                <button
                  id="BtnSearch"
                  @click="LoadData()"
                  type="button"
                  class="btn btn-primary mx-3 mb-3"
                >
                  <i class="icon-search"></i>{{ language.Search }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="pat" class="flex-div">
          <div id="card-table" class="card-body flex-div">
            <div class="table-responsive flex">
              <table class="table fixed">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">{{ language.Id }}</th>
                    <th scope="col">{{ language.Username }}</th>
                    <th scope="col">{{ language.Amount }}</th>
                    <th scope="col">{{ language.Title }}</th>
                    <th scope="col">{{ language.Employee }}</th>
                    <th scope="col">{{ language.DateCreate }}</th>
                    <th scope="col">{{ language.operations }}</th>
                  </tr>
                </thead>
                <tbody v-if="HasList">
                  <tr v-for="Wallet in list" :key="Wallet.id" >
                    <td>{{ Wallet.id }}</td>
                    <td>{{ Wallet.userName }}</td>
                    <td>{{ Wallet.amount }}</td>
                    <td v-if="Wallet.walletEnum == 0" :class="{'text-success':Wallet.isDeposit,'text-danger':!Wallet.isDeposit}">
                      {{ language.CreateOrder }} <span
                        v-if="Wallet.isDeposit"
                        class="icon-angle-up text-success"
                      ></span>
                      <span
                        v-if="!Wallet.isDeposit"
                        class="icon-angle-down text-danger"
                      ></span>
                    </td>
                    <td v-if="Wallet.walletEnum == 1" :class="{'text-success':Wallet.isDeposit,'text-danger':!Wallet.isDeposit}">
                      {{ language.FreindlyPack }} <span
                        v-if="Wallet.isDeposit"
                        class="icon-angle-up text-success"
                      ></span>
                      <span
                        v-if="!Wallet.isDeposit"
                        class="icon-angle-down text-danger"
                      ></span>
                    </td>
                    <td v-if="Wallet.walletEnum == 2" :class="{'text-success':Wallet.isDeposit,'text-danger':!Wallet.isDeposit}">
                      {{ language.IncreaseWallet }} <span
                        v-if="Wallet.isDeposit"
                        class="icon-angle-up text-success"
                      ></span>
                      <span
                        v-if="!Wallet.isDeposit"
                        class="icon-angle-down text-danger"
                      ></span>
                    </td>
                    <td v-if="Wallet.walletEnum == 3" :class="{'text-success':Wallet.isDeposit,'text-danger':!Wallet.isDeposit}">
                      {{ language.CancelOrder }} <span
                        v-if="Wallet.isDeposit"
                        class="icon-angle-up text-success"
                      ></span>
                      <span
                        v-if="!Wallet.isDeposit"
                        class="icon-angle-down text-danger"
                      ></span>
                    </td>
                    <td v-if="Wallet.walletEnum == 4" :class="{'text-success':Wallet.isDeposit,'text-danger':!Wallet.isDeposit}">
                      {{ language.ServeMenu }} <span
                        v-if="Wallet.isDeposit"
                        class="icon-angle-up text-success"
                      ></span>
                      <span
                        v-if="!Wallet.isDeposit"
                        class="icon-angle-down text-danger"
                      ></span>
                    </td>
                    <td v-if="Wallet.operatorId == Wallet.userId">-</td>
                    <td v-if="Wallet.operatorId != Wallet.userId">
                      {{ Wallet.operatorUserName }}
                    </td>
                    <td>{{ Wallet.dateCreate }}</td>
                    <td class="text-center"><span class="icon-info-circle text-info" @click="walletInfo(Wallet.text)">
                    </span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer bg-white">
            <nav aria-label="Page navigation" class="float-left">
              <ul class="pagination pagination-sm">
                <li v-if="Pageid > 1" class="page-item">
                  <a
                    aria-label="Previous"
                    @click="PageChev(0)"
                    class="page-link"
                    ><span aria-hidden="true"
                      ><i class="icon-chevron-right m-0"></i></span
                  ></a>
                </li>
                <li
                  v-for="index in PageCount"
                  :key="index"
                  :class="{ active: Pageid == index }"
                  class="page-item"
                >
                  <a @click="Page(index)" class="page-link"
                    >{{ index }}<span class="sr-only"></span
                  ></a>
                </li>

                <li v-if="PageCount + 1 > Pageid" class="page-item">
                  <a aria-label="Next" @click="PageChev(1)" class="page-link"
                    ><span aria-hidden="true"
                      ><i class="icon-chevron-left m-0"></i></span
                  ></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WalletInfo from './WalletInfo.vue'
import { openModal } from "jenesius-vue-modal";
import axios from "axios";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import {
  Loading,
  TitleBar,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";
export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      PageCount: 1,
      Pageid: 1,
      HasList: false,
      UserId: 0,
      list: [],
      UserName: "",
      UserFullName: "",
      walletEnum: null,
      OperatorUserName: "",
      OperatorFullName: "",
      DateCreate: "",
      IsDeposit: 0,
    };
  },
  mounted() {
    TitleBar(this.language.History + " " + this.language.Wallet);
    this.LoadData();
  },
  methods: {
    Page(id) {
      this.Pageid = id;
      this.LoadData();
    },
    PageChev(i) {
      if (i === 1) {
        this.Pageid++;
      } else if (i === 0) {
        this.Pageid = this.Pageid - 1;
      }
      this.LoadData();
    },
    walletInfo(text) {
     openModal(WalletInfo, {textProp: text})
    },
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get("/Wallet/GetList", {
            params: {
              PageId: this.Pageid,
              UserId: null,
              UserName: this.UserName,
              UserFullName: this.UserFullName,
              walletEnum: this.walletEnum,
              OperatorUserName: this.OperatorUserName,
              OperatorFullName: this.OperatorFullName,
              DateCreate: this.DateCreate,
              IsDeposit:
                this.IsDeposit == 0 ? null : this.IsDeposit == 1 ? true : false,
            },
          })
          .then((res) => {
            if (res.data.isSuccess) {
              if (res.data.result.list.length > 0) {
                this.HasList = true;
                this.list = res.data.result.list;
              }else{
                this.HasList = false;
                 this.list=null;
              }
              Loading(0);
            }
          });
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
  },
};
</script>

