<template>
  <tr>
    <td>
      {{ Category.id }}
    </td>
    <td v-if="languae == 'fa'">
      {{ Category.name }}
    </td>
    <td v-if="languae == 'tr'">
      {{ Category.trName }}
    </td>
    <td>
      <span
        data-placement="top"
        data-toggle="tooltip"
        title=""
        data-original-title="ویرایش"
      >
        <a @click="Edit()"
          ><i class="btn icon-edit mr-2 text-primary p-0 loader"></i
        ></a>
      </span>
      <span
        data-placement="top"
        data-toggle="tooltip"
        title=""
        data-original-title="ویرایش"
      >
        <a
          class="btn btn-secondary text-white btn-sm"
          @click="ChangeDelete()"
          v-if="Category.isDeleted"
          >{{ language.IsDelete }}</a
        >
        <a
          class="btn btn-danger text-white btn-sm"
          @click="ChangeDelete()"
          v-if="!Category.isDeleted"
          >{{ language.Delete }}</a
        >
      </span>
    </td>
  </tr>
</template>
<script>
import { openModal } from "jenesius-vue-modal";
import CategoryCreateOrEdit from "./CategoryCreateOrEdit.vue";
import { ReloadList, Loading } from "../../../../public/js/Utilities.js";
import axios from "axios";
export default {
  data() {
    return {
      languae: localStorage.getItem("language") == "tr" ? "tr" : "fa",
    };
  },
  mounted() {},
  props: ["Category"],
  methods: {
    Edit() {
      openModal(CategoryCreateOrEdit, {
        idProp: this.Category.id,
        titleProp: this.Category.name,
        isReservetionProp: this.Category.isReservetion,
        ImageProp: this.Category.image,
        trTitleProp: this.Category.trName,
        isMenuProp: this.Category.isMenu,
        isRoomProp:this.Category.isHotel
      });
    },
    ChangeDelete() {
      Loading(1);
      axios.post(`/Category/ChangeDelete?id=${this.Category.id}`).then(() => {
        ReloadList();
      });
    },
  },
};
</script>