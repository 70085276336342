<template>
    <div id="card-table" class="card-body flex-div">
      <div class="table-responsive flex">
        <table class="table fixed">
          <thead class="thead-light">
            <tr>
             
              <th scope="col">{{language.Title}}</th>
              <th scope="col">{{language.Username}}</th>
              <th scope="col">{{language.required}}</th>
            </tr>
          </thead>
          <tbody>
            <SignupAct-item
              v-for="item in SignupActs"
              :key="item.id"
              :SignupAct="item"
             
            
            ></SignupAct-item>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  <script>
  
  import SignupActItem from "./SignupActItem.vue";
  
  export default {
    props:["SignupActs"],
    components: {
        SignupActItem,
      
    },
    methods:{
       
        
    }
   
  };
  </script>