<template>
  <tr>
    <td>
      {{ User.id }}
    </td>
    <td>
      {{ User.userName }}
    </td>
    <td>
      {{ User.telNu }}
    </td>
    <td>
      {{ User.fullName }}
      <small v-if="User.email!=null && User.email!=''">(({{ language.Email }}:{{ User.email }}))</small>
    </td>
    <td>
      {{ User.passportNu }}
    </td>
    <td>
      {{ User.dateCreate }}
    </td>
    <td>
      <span v-if="User.gender == 1">{{ language.Male }}</span>
      <span v-if="User.gender == 2">{{ language.Female }}</span>
      <span v-if="User.gender == 3">{{ language.Other }}</span>
    </td>
    <td>
      <button @click="UserInfo()" class="badge badge-info px-3 py-1">
        {{ language.UserInfo }}
      </button>
      <button @click="CreateOrder()" class="badge badge-success px-3 py-1">
        {{ language.CreateOrder }}
      </button>
      <button @click="Wallet()" class="badge badge-warning px-3 py-1">
        {{ language.Wallet }}
      </button>

      <!-- <select
        v-model="SettingOption"
        @change="Setting()"
        class="form-control form-control-sm"
        v-if="User.roleId != 4"
      >
        <option :value="0">{{ language.Setting }}</option>
        <option v-if="isAdminRole" :value="1">{{ language.UserInfo }}</option>
        <option :value="2" v-if="User.roleId == 1 && isAdminRole">
          {{ language.Beprovider }}
        </option>
        <option :value="3" v-if="User.roleId == 2 && isAdminRole">
          {{ language.ProviderInfo }}
        </option>
        <option :value="4" v-if="User.roleId == 1">
          {{ language.CreateOrder }}
        </option>
        <option :value="5" v-if="User.roleId == 1">
          {{ language.Wallet }}
        </option>
      </select> -->
    </td>
  </tr>
</template>
<script>
import { openModal } from "jenesius-vue-modal";
import UserCreateOrEdit from "./UserCreateOrEdit.vue";
// import ProviderCreateOrEdit from "../provider/ProviderCreateOrEdit.vue";
import IncreaseWallet from "./IncreaseWallet.vue";

export default {
  data() {
    return {
      SettingOption: 0,
    };
  },
  props: ["User"],
  methods: {
    // Setting() {
    //   if (this.SettingOption != 0) {
    //     if (this.SettingOption == 1) {
    //       openModal(UserCreateOrEdit, {
    //         idProp: this.User.id,
    //         TelNuProp: this.User.telNu,
    //         EmailProp: this.User.email,
    //         PassportNuProp: this.User.passportNu,
    //         PasswordProp: this.User.password,
    //         GenderProp: this.User.gender,
    //         WhatsappAccountProp: this.User.whatsappAccount,
    //         ResidenceProp: this.User.residence,
    //         FullNameProp: this.User.fullName,
    //         IsProviderProp: false,
    //       });
    //       this.SettingOption = 0;
    //     } else if (this.SettingOption == 2) {
    //       //Create Provider
    //       openModal(ProviderCreateOrEdit, {
    //         IdProp: null,
    //         UserIdProp: this.User.id,
    //       });
    //       this.SettingOption = 0;
    //     } else if (this.SettingOption == 3) {
    //       //Edit Provider
    //       openModal(ProviderCreateOrEdit, {
    //         IdProp: this.User.providerId,
    //         UserIdProp: this.User.id,
    //       });
    //       this.SettingOption = 0;
    //     } else if (this.SettingOption == 4) {
    //       this.$router.push({ path: `/CreateOrder/${this.User.id}` });
    //     } else if (this.SettingOption == 5) {
    //       openModal(IncreaseWallet, {
    //         IdProp: this.User.id,
    //       });
    //       this.SettingOption = 0;
    //     }
    //   }
    // },

    UserInfo() {
      openModal(UserCreateOrEdit, {
        idProp: this.User.id,
        TelNuProp: this.User.telNu,
        EmailProp: this.User.email,
        PassportNuProp: this.User.passportNu,
        PasswordProp: this.User.password,
        GenderProp: this.User.gender,
        WhatsappAccountProp: this.User.whatsappAccount,
        ResidenceProp: this.User.residence,
        FullNameProp: this.User.fullName,
        IsProviderProp: false,
      });
    },
    CreateOrder() {
      this.$router.push({ path: `/CreateOrder/${this.User.id}` });
    },
    Wallet() {
      openModal(IncreaseWallet, {
        IdProp: this.User.id,
      });
    },
  },
};
</script>