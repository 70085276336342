<template>
  <div class="row flex-div">
    <div class="col-md-12 flex pb-3 pt-3">
      <div class="card flex-div border-0 main-shadow">
        <div class="card-body flex-div">
          <div class="row flex-div">
            <div class="panel-box panel-setting flex-div">
              <div class="card shadow-full border-0 flex-div">
                <div class="card-header">
                  <h5 class="panel-box-title">
                    {{ language.Setting }}
                    <i class="toggle-icon"></i>
                  </h5>
                </div>
                <div class="card-body flex">
                  <button
                    @click="EditProvider()"
                    class="btn btn-light mb-3 btn-block"
                  >
                    <i class="icon icon-info ml-2"></i>
                    {{ language.ProviderInfo }}
                  </button>

                  <button
                    @click="Checkouting()"
                    class="btn btn-light mb-3 btn-block"
                  >
                    <i class="icon icon-dollar-sign ml-2"></i>
                    {{ language.Checkouting }} {{ language.AllOrder }}
                  </button>

                  <button
                    @click="EditBankCard()"
                    class="btn btn-light mb-3 btn-block"
                  >
                    <i class="icon icon-credit-card ml-2"></i>
                    {{ language.BankCard }}
                  </button>

                  <button
                    @click="EditProviderOptionValue()"
                    class="btn btn-light mb-3 btn-block"
                  >
                    <i class="icon icon-clock ml-2"></i>
                    {{ language.ProviderOptionValue }}
                  </button>

                  <button
                    v-if="HasMenu"
                    @click="GotoProviderMenu()"
                    class="btn btn-light mb-3 btn-block"
                  >
                    <i class="icon icon-list ml-2"></i>
                    {{ language.ServeMenu }}
                  </button>

                  <button
                    v-if="HasMenu"
                    @click="BeforeProvideMenuCheckout()"
                    class="btn btn-light mb-3 btn-block"
                  >
                    <i class="icon-dollar-sign ml-2"></i>
                    {{ language.Checkouting }} {{ language.ServeMenu }}
                  </button>
                  <button
                    @click="EditMenuImage()"
                    class="btn btn-light mb-3 btn-block"
                  >
                    <i class="icon icon-image ml-2"></i>
                    {{ language.MainImage }}
                  </button>
                  <button
                    @click="EditMainImage()"
                    class="btn btn-light mb-3 btn-block"
                  >
                    <i class="icon icon-image ml-2"></i>
                    {{ language.ProviderSlider }}
                  </button>
                  <button
                    @click="EditMap()"
                    class="btn btn-light mb-3 btn-block"
                  >
                    <i class="icon icon-map-marker-alt ml-2"></i>
                    {{ language.Map }}
                  </button>
                </div>
              </div>
            </div>
            <div class="panel-box panel-orders flex-div">
              <div class="card shadow-full border-0 flex-div">
                <div class="card-header">
                  <h5 class="panel-box-title">
                    {{ language.ListOfOrders }}
                    <i class="toggle-icon"></i>
                  </h5>
                </div>

                <div class="card-body flex-div">
                  <div class="form-inline">
                    <div class="form-group ml-2 mb-2">
                      <input
                        id="Id"
                        class="form-control"
                        type="text"
                        :placeholder="language.Id"
                        v-model="SearchInput.Id"
                        @change="Search()"
                      />
                    </div>
                    <div class="form-group ml-2 mb-2">
                      <input
                        id="UserName"
                        class="form-control"
                        type="text"
                        :placeholder="language.Username"
                        v-model="SearchInput.UserName"
                        @change="Search()"
                      />
                    </div>

                    <div class="form-group ml-2 mb-2">
                      <select
                        v-model="SearchInput.isCheckout"
                        class="form-control"
                      >
                        <option selected :value="0">{{ language.All }}</option>
                        <option :value="1">{{ language.IsCheckout }}</option>
                        <option :value="2">{{ language.UnIsCheckout }}</option>
                      </select>
                    </div>

                    <div class="form-group ml-2 mb-2">
                      <label class="mr-2">{{ language.StartDate }}</label>
                      <datepicker v-model="SearchInput.StartDate"></datepicker>
                    </div>
                    <div class="form-group ml-2 mb-2">
                      <label class="mr-2">{{ language.EndDate }}</label>
                      <datepicker v-model="SearchInput.EndDate"></datepicker>
                    </div>

                    <button
                      id="BtnSearch"
                      @click="Search()"
                      type="button"
                      class="btn btn-primary mx-2 mb-2"
                    >
                      <i class="icon-search"></i>{{ language.Search }}
                    </button>
                  </div>

                  <div id="card-table" class="card-body flex-div border">
                    <div class="table-responsive flex">
                      <table class="table fixed">
                        <thead class="thead-light">
                          <tr>
                            <th scope="col">{{ language.Id }}</th>
                            <th scope="col">{{ language.Username }}</th>
                            <th scope="col">{{ language.Amount }}</th>
                            <th scope="col">{{ language.IsCheckout }}</th>
                            <th scope="col">{{ language.DateCreate }}</th>
                            <th scope="col">{{ language.GoDate }}</th>
                            <th scope="col">{{ language.operations }}</th>
                          </tr>
                        </thead>
                        <tbody v-if="HasList">
                          <tr v-for="order in list" :key="order.id">
                            <td>
                              {{ order.id }}
                            </td>
                            <td>
                              {{ order.userName }}
                            </td>
                            <td>
                              {{ order.amount }}
                            </td>
                            <td>
                              <span
                                v-if="order.isCheckout"
                                class="text-success"
                                >{{ language.IsCheckout }}</span
                              >
                              <span
                                v-if="!order.isCheckout"
                                class="text-danger"
                              >
                                {{ language.UnIsCheckout }}
                              </span>
                            </td>
                            <td>
                              {{ order.dateCreate }}
                            </td>
                            <td>
                              {{ order.goDate }}
                            </td>
                            <td>
                              <span
                                data-placement="top"
                                data-toggle="tooltip"
                                title=""
                                data-original-title=""
                              >
                                <button
                                  @click="Info(order.id)"
                                  class="badge badge-info px-3 py-1"
                                >
                                  <i
                                    class="btn icon-info p-0 loader text-white m-0"
                                  ></i>
                                </button>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="card-footer bg-white">
                  <nav aria-label="Page navigation" class="float-left">
                    <ul class="pagination pagination-sm">
                      <li v-if="Pageid > 1" class="page-item">
                        <a
                          aria-label="Previous"
                          @click="PageChev(0)"
                          class="page-link"
                          ><span aria-hidden="true"
                            ><i class="icon-chevron-right m-0"></i></span
                        ></a>
                      </li>

                      <li
                        v-for="index in PageCount"
                        :key="index"
                        :class="{ active: Pageid == index }"
                        class="page-item"
                      >
                        <a @click="Page(index)" class="page-link"
                          >{{ index }}<span class="sr-only"></span
                        ></a>
                      </li>

                      <li v-if="PageCount + 1 > Pageid" class="page-item">
                        <a
                          aria-label="Next"
                          @click="PageChev(1)"
                          class="page-link"
                          ><span aria-hidden="true"
                            ><i class="icon-chevron-left m-0"></i></span
                        ></a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- {{route.params.Provider}} -->
</template>
<script>
import axios from "axios";
import Datepicker from "@vuepic/vue-datepicker";
import { useRoute } from "vue-router";
import "@vuepic/vue-datepicker/dist/main.css";
import { openModal } from "jenesius-vue-modal";
import ProviderCreateOrEdit from "./ProviderCreateOrEdit.vue";
import MainImage from "./MainImage.vue";
import OrderInfo from "../../pages/order/OrderInfo.vue";
import ProviderOptionValue from "./ProviderOptionValue.vue";
import ProviderCheckout from "./ProviderCheckout.vue";
import ProviderBankCard from "./ProviderBankCard.vue";
import ProviderMenuCheckout from "./ProviderMenuCheckout.vue";
import {
  Loading,
  TitleBar,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";
import MenuImage from "./MenuImage.vue";
export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      PageCount: 1,
      Pageid: 1,
      HasList: false,
      UserId: 0,
      ProviderShopName: "",
      Amount: "",
      DateCreate: "",
      list: [],
      GoDate: "",
      SiteQuota: "",
      ShopQuota: "",
      ShopName: "",
      HasMenu: false,
      IsHotel: false,
      SearchInput: {
        Id: "",
        ProviderId: null,
        UserName: "",
        isCheckout: 0,
        StartDate: 0,
        EndDate: 0,
      },

      route: useRoute(),
      file: {},
      fileSelected: false,
      showFileSelect: false,
    };
  },
  mounted() {
    TitleBar(this.language.ProviderPanel);
    this.SearchInput.ProviderId = this.route.params.Provider;
    if (CheckNetwork()) {
      axios
        .get(
          `/Provider/HasMenuProvider?providerId=${this.route.params.Provider}`
        )
        .then((res) => {
          if (res.data.isSuccess) {
            this.HasMenu = res.data.result;
          }
        });
    }
    this.LoadData();
  },
  methods: {
    Page(id) {
      this.Pageid = id;
      this.LoadData();
    },
    Info(id) {
      openModal(OrderInfo, {
        idProp: id,
      });
    },
    PageChev(i) {
      if (i === 1) {
        this.Pageid++;
      } else if (i === 0) {
        this.Pageid = this.Pageid - 1;
      }
      this.LoadData();
    },
    BeforeProvideMenuCheckout() {
      openModal(ProviderMenuCheckout, {
        IdProp: this.SearchInput.ProviderId,
      });
    },
    EditMainImage() {
      openModal(MainImage, {
        IdProp: this.SearchInput.ProviderId,
      });
    },
    EditMenuImage() {
      openModal(MenuImage, {
        IdProp: this.SearchInput.ProviderId,
      });
    },
    GotoProviderMenu() {
      this.$router.push(`/ProviderMenuPanel/${this.SearchInput.ProviderId}`);
    },
    EditMap() {
      Loading(1);
      axios.get("/Config/MapUrl").then((res) => {
        Loading(0);
        window.open(
          res.data.result + `?id=${this.SearchInput.ProviderId}&IsGet=0`
        );
      });
    },
    EditBankCard() {
      openModal(ProviderBankCard, {
        IdProp: this.SearchInput.ProviderId,
      });
    },
    EditProvider() {
      //Edit Provider
      openModal(ProviderCreateOrEdit, {
        IdProp: this.SearchInput.ProviderId,
        UserIdProp: 0,
      });
    },
    EditProviderOptionValue() {
      //Edit Provider Option Value
      openModal(ProviderOptionValue, {
        IdProp: this.SearchInput.ProviderId,
      });
    },
    Checkouting() {
      openModal(ProviderCheckout, {
        IdProp: this.SearchInput.ProviderId,
      });
    },
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get(`/Provider/GetById?id=${this.SearchInput.ProviderId}`)
          .then((res) => {
            TitleBar(
              this.language.ProviderPanel + " " + res.data.result.shopName
            );
            this.IsHotel = res.data.result.isHotel;
          });
        axios
          .get(`/Order/GetByProviderIdList`, {
            params: {
              PageId: this.Pageid,
              id: this.SearchInput.Id,
              userName: this.SearchInput.UserName,
              providerId: this.SearchInput.ProviderId,
              isCheckout: this.SearchInput.isCheckout,
              StartDate: this.StartDate,
              EndDate: this.EndDate,
            },
          })
          .then((res) => {
            if (res.data.isSuccess) {
              if (res.data.result.list.length == 0) {
                this.HasList = false;
                Loading(0);
              } else {
                this.HasList = true;
                this.PageCount = res.data.result.pageCount;
                this.list = res.data.result.list;
                Loading(0);
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
    Search() {
      this.LoadData();
    },
  },
};
</script>
