<template>
  <tr>
    <td>
      {{ name }}
    </td>
    <td>
      <div class="custom-control custom-radio ml-3 mb-3">
        <input
          :id="id + 'isUserName'"
          type="radio"
          name="customRadio"
          class="custom-control-input"
          v-model="isUserName"
          @change="isUserNameChange()"
          :checked="isUserName"
        />
        <label class="custom-control-label" :for="id + 'isUserName'"></label>
      </div>
    </td>

    <td>
      <div class="custom-control custom-checkbox ml-3 mb-3">
        <input
          :id="id + 'isRequired'"
          type="checkbox"
          class="custom-control-input"
          v-model="isRequired"
          @change="isRequiredChange(id)"
        />
        <label class="custom-control-label" :for="id + 'isRequired'"></label>
      </div>
    </td>
  </tr>
</template>
  <script>
import axios from "axios";
import {
  Loading,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";
export default {
  props: ["SignupAct"],
  data() {
    return {
      id: Number,
      name: "",
      isRequired: false,
      isUserName: false,
    };
  },
  mounted() {
    this.id = this.SignupAct.id;
    this.name = this.SignupAct.name;
    this.isRequired = this.SignupAct.isRequired;
    this.isUserName = this.SignupAct.isUserName;
  },
  methods: {
    isUserNameChange() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .post(`/SignupAct/IsUserNameChange?id=${this.id}`)
          .catch((err) => console.log(err));
        document.getElementById(`${this.id}isRequired`).checked = true;
        this.Disable = true;
        Loading(0);
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
    isRequiredChange() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .post(`/SignupAct/IsRequiredChange?id=${this.id}`)
          .catch((err) => console.log(err));
        Loading(0);
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
  },
};
</script>