<template>
  <div class="modal show">
    <div
      class="
        modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable
      "
      role="document"
    >
      <div id="modal-content" class="modal-content">
        <div class="modal-header">
          <h6 v-if="id == 0" class="modal-title">{{ language.Create }}</h6>
          <h6 v-if="id != 0" class="modal-title">{{ language.Edit }}</h6>
          <button
            @click="closeModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-row">
              <div class="col-12" v-for="item in SignupActlist" :key="item.id">
                <!-- TelNuInput -->
                <div class="row" v-if="item.name == 'TelNu'">
                  <label class="col-lg-3 col-form-label">
                    {{ language.TelNu }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="TelNu"
                      :placeholder="language.TelNu"
                    />
                    <small v-if="item.isRequired" class="text-danger">{{
                      language.required
                    }}</small>

                    <small v-if="item.isUserName" class="text-danger">
                      - {{ language.Username }}</small
                    >
                  </div>
                </div>

                <!-- EmailInput -->
                <div class="row" v-if="item.name == 'Email'">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Email }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="Email"
                      :placeholder="language.Email"
                    />
                    <small v-if="item.isRequired" class="text-danger">{{
                      language.required
                    }}</small>

                    <small v-if="item.isUserName" class="text-danger">
                      - {{ language.Username }}</small
                    >
                  </div>
                </div>

                <!-- PassportNuInput -->
                <div class="row" v-if="item.name == 'PassportNu'">
                  <label class="col-lg-3 col-form-label">
                    {{ language.PassportNu }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="PassportNu"
                      :placeholder="language.PassportNu"
                    />
                    <small v-if="item.isRequired" class="text-danger">{{
                      language.required
                    }}</small>

                    <small v-if="item.isUserName" class="text-danger">
                      - {{ language.Username }}</small
                    >
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.FullName }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="FullName"
                      :placeholder="language.FullName"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Password }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      style="text-security: disc; -webkit-text-security: disc"
                      type="password"
                      class="form-control"
                      v-model="Password"
                      :placeholder="language.Password"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Gender }}
                  </label>
                  <div class="form-group col-lg-6">
                    <select v-model="Gender" class="form-control">
                      <option :value="1">{{ language.Male }}</option>
                      <option :value="2">{{ language.Female }}</option>
                      <option :value="3">{{ language.Other }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.WhatsappAccount }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="WhatsappAccount"
                      :placeholder="language.WhatsappAccount"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.BirthdayDate }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input  class="form-control" v-model="BirthdayDate" />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Residence }}
                  </label>
                  <div class="form-group col-lg-6">
                    <textarea
                      v-model="Residence"
                      class="form-control"
                      :placeholder="language.Residence"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                @click="submitUser()"
                v-if="id == 0"
                class="btn btn-sm btn-success"
              >
                {{ language.Create }}
              </button>
              <button
                @click="submitUser()"
                v-if="id != 0"
                class="btn btn-sm btn-success"
              >
                {{ language.Edit }}
              </button>
              <button
                @click="closeModal()"
                type="button"
                class="btn btn-sm btn-info"
                data-dismiss="modal"
              >
                {{ language.Close }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { closeModal, openModal } from "jenesius-vue-modal";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ProviderCreateOrEdit from "../provider/ProviderCreateOrEdit.vue";
import OperatorCreateOrEdit from "../Operator/OperatorCreateOrEdit.vue";
import {
  Loading,
  CheckNetwork,
  ShowAlert,
  ReloadList,
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      v$: useValidate(),
      id: Number,
      TelNu: "",
      Email: "",
      PassportNu: "",
      FullName: "",
      Password: "",
      Gender: Number,
      WhatsappAccount: "",
      Residence: "",
      BirthdayDate: null,
      SignupActlist: [],
    };
  },
  validations() {
    return {
      FullName: { required },
      Gender: { required },
    };
  },

  props: {
    idProp: Number,
    TelNuProp: String,
    EmailProp: String,
    PassportNuProp: String,
    PasswordProp: String,
    GenderProp: Number,
    WhatsappAccountProp: String,
    ResidenceProp: String,
    FullNameProp: String,
    IsProviderProp: Boolean,
    IsEmployeeProp: Boolean,
  },
  mounted() {
    Loading(1);
    if (CheckNetwork()) {
      axios
        .get(`/SignupAct/GetList`)
        .then((res) => {
          if (res.data.isSuccess) {
            if (res.data.result.length == 0) {
              Loading(0);
            } else {
              this.SignupActlist = res.data.result;
              Loading(0);
            }
          }
        })
        .catch((err) => console.log(err));

      if (this.idProp != null && this.idProp != 0) {
        axios
          .get(`/User/GetById`, {
            params: {
              id: this.idProp,
            },
          })
          .then((res) => {
            if (res.data.isSuccess) {
              this.id = res.data.result.id;
              this.TelNu = res.data.result.telNu;
              this.Email = res.data.result.email;
              this.PassportNu = res.data.result.passportNu;
              this.FullName = res.data.result.fullName;
              this.Gender = res.data.result.gender;
              this.WhatsappAccount = res.data.result.whatsappAccount;
              this.Residence = res.data.result.residence;
              this.BirthdayDate = res.data.result.birthdayDate;
            }
          })
          .catch((err) => console.log(err));
      }
      this.id = 0;
    } else {
      Loading(0);
      ShowAlert("danger", this.language.error, this.language.Checkyourinternet);
    }
  },
  methods: {
    closeModal() {
      closeModal();
    },
    submitUser() {
      Loading(1);
      this.v$.$validate().then((res) => {
        if (res) {
          if (CheckNetwork()) {
            axios
              .post("/User/CreateOrEditUser", {
                id: this.id,
                userName: "-",
                fullName: this.FullName,
                passwordHash: this.Password,
                telNu: this.TelNu,
                email: this.Email,
                passportNu: this.PassportNu,
                roleId: 1,
                dateCreate: null,
                profileImage: null,
                birthdayDate: this.BirthdayDate,
                gender: this.Gender,
                residence: this.Residence,
                whatsappAccount: this.WhatsappAccount,
              })
              .then((res) => {
                if (res.data.isSuccess) {
                  Loading(0);
 
                  if (this.IsProviderProp) {
                    closeModal();
                    openModal(ProviderCreateOrEdit, {
                      IdProp: null,
                      UserIdProp: res.data.result.id,
                    });
                  }
                  if (this.IsEmployeeProp) {
                    closeModal();
                    openModal(OperatorCreateOrEdit, {
                      UserIdProp: res.data.result.id,
                      EmployeePercentProp: 1,
                    });
                  } else {
                    closeModal();
                    ReloadList();
                    ShowAlert("success", "", this.language.success);
                  }
                } else {
                  Loading(0);
                  if (res.data.message == "TelNu-IsRequired") {
                    ShowAlert(
                      "danger",
                      this.language.error,
                      this.language.TelNuIsRequired
                    );
                  } else if (res.data.message == "Email-IsRequired") {
                    ShowAlert(
                      "danger",
                      this.language.error,
                      this.language.EmailIsRequired
                    );
                  } else if (res.data.message == "PassportNu-IsRequired") {
                    ShowAlert(
                      "danger",
                      this.language.error,
                      this.language.PassportNuIsRequired
                    );
                  } else if (res.data.message == "Duplicate") {
                    ShowAlert(
                      "danger",
                      this.language.error,
                      this.language.UserDuplicate
                    );
                  }
                }
              })
              .catch((err) => console.log(err));
          }
        } else {
          Loading(0);
          ShowAlert("danger", this.language.error, this.language.ValidateForm);
        }
      });
    },
  },
};
</script>
