<template>
  <div class="modal show">
    <div
      class="
        modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable
      "
      role="document"
    >
      <div id="modal-content" class="modal-content">
        <div class="modal-header">
          <h6 v-if="id == 0 || id == null" class="modal-title">
            {{ language.Create }}
          </h6>
          <h6 v-if="id != 0" class="modal-title">{{ language.Edit }}</h6>
          <button
            @click="closeModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-row">
              <div class="col-12">
                <div class="col-12">
                  <div class="row">
                    <label class="col-lg-3 col-form-label">
                      {{ language.Quota }} (%)
                    </label>
                    <div class="form-group col-lg-6">
                      <input
                        type="number"
                        minlength="1"
                        maxlength="99"
                        class="form-control"
                        v-model="employeePercent"
                        :placeholder="language.Quota"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click="submitEmployee()"
              v-if="id == 0"
              class="btn btn-sm btn-success"
            >
              {{ language.Create }}
            </button>
            <button
              @click="submitEmployee()"
              v-if="id != 0"
              class="btn btn-sm btn-success"
            >
              {{ language.Edit }}
            </button>
            <button
              @click="closeModal()"
              type="button"
              class="btn btn-sm btn-info"
              data-dismiss="modal"
            >
              {{ language.Close }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { closeModal } from "jenesius-vue-modal";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  Loading,
  CheckNetwork,
  ShowAlert,
  ReloadList,
} from "../../../../public/js/Utilities.js";
export default {
  components: {},
  data() {
    return {
      v$: useValidate(),
      userId: 0,
      employeePercent: 0,
    };
  },
  validations() {
    return {
      userId: { required },
      employeePercent: { required },
    };
  },

  props: {
    UserIdProp: Number,
    EmployeePercentProp: Number,
  },
  mounted() {
    Loading(1);
    if (CheckNetwork()) {
      Loading(1);
      axios
        .get(`/User/EmployeeInfo?UserId=${this.UserIdProp}`)
        .then((res) => {
          if (res.data.isSuccess) {
            this.userId = res.data.result.userId;
            this.employeePercent = res.data.result.employeePercent;
            Loading(0);
          }
        })
        .catch((err) => console.log(err));
    } else {
      Loading(0);
      ShowAlert("danger", this.language.error, this.language.Checkyourinternet);
    }
  },
  methods: {
    closeModal() {
      closeModal();
    },
    submitEmployee() {
      Loading(1);
      this.v$.$validate().then((res) => {
        if (res) {
          if (CheckNetwork()) {
            if (this.employeePercent > 99 || this.employeePercent < 1) {
              ShowAlert(
                "danger",
                this.language.error,
                this.language.ErrorofPercent
              );
              Loading(0);
              return;
            }

            axios
              .post("/User/CreateOrEditToEmployee", {
                userId: this.userId,
                employeePercent: this.employeePercent,
              })
              .then(() => {
                  Loading(0);
                  closeModal();
                  ReloadList();
                  ShowAlert("success", "✓", this.language.success);
                
              })
              .catch((err) => console.log(err));
          }
        } else {
          Loading(0);
          ShowAlert("danger", this.language.error, this.language.ValidateForm);
        }
      });
    },
  },
};
</script>
