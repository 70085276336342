<template>
  <div class="modal show">
    <div
      class="
        modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable
      "
      role="document"
    >
      <div id="modal-content" class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">
            {{ language.Checkouting }}
          </h6>
          <button
            @click="closeModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>{{ language.Sum }} {{ language.Creditor }}:</label>
                  <label>{{ Creditor }}</label>
                </div>
                <div class="form-group col-md-6">
                  <label>{{ language.Sum }} {{ language.Debtor }}:</label>
                  <label>{{ Debtor }}</label>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>{{ language.Quota }} {{ language.Site }}:</label>
                  <label>{{ SumPaymentSite }}</label>
                </div>
              </div>
              <button
                :disabled="Debtor == 0 && Creditor == 0"
                class="btn btn-success mb-2 float-right"
                @click="checkOut()"
              >
                {{ language.Checkouting }}
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { closeModal } from "jenesius-vue-modal";
import {
  Loading,
  CheckNetwork,
  ShowAlert,
  ReloadList,
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      Id: 0,
      Creditor: 0,
      Debtor: 0,
      SumPaymentSite: 0,
    };
  },
  props: {
    IdProp: Number,
  },
  mounted() {
    this.LoadData();
  },
  methods: {
    closeModal() {
      closeModal();
    },
    checkOut() {
      Loading(1);
      if (CheckNetwork()) {
        axios.post(`/User/OperatorCheckouting?UserId=${this.IdProp}`);
        Loading(0);
        this.closeModal();
        ReloadList();
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get(`/User/BeforeOperatorCheckout?UserId=${this.IdProp}`)
          .then((res) => {
            if (res.data.isSuccess) {
              this.Creditor = res.data.result.sumCreditor;
              this.Debtor = res.data.result.sumDebtor;
              this.SumPaymentSite = res.data.result.sumPaymentSite;
              Loading(0);
            }
          })
          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
  },
};
</script>