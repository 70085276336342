<template>
  <div class="side-nav" v-if="HasLogin">
    <div class="side-nav-inner">
      <div class="side-nav-logo">
        <a href="#">
          <div
            class="logo logo-dark"
            style="background-image: url('assets/img/logo.png')"
          >
            <span></span>
          </div>
        </a>
        <div class="mobile-toggle side-nav-toggle" @click="toggleMenu">
          <a href="#">
            <i class="icon-chevron-left"></i>
          </a>
        </div>
      </div>
      <ul class="side-nav-menu scrollable">
        <li  class="nav-item active">
          <router-link v-if="isAdminRole" to="/" class="Loader" @click="toggleMenuMd()">
            <span class="icon-holder">
              <i class="icon-home"></i>
            </span>
            <span class="title">{{ language.Dashboard }}</span>
          </router-link>
          <router-link v-if="!isAdminRole" to="/OperatorDashboard" class="Loader" @click="toggleMenuMd()">
            <span class="icon-holder">
              <i class="icon-home"></i>
            </span>
            <span class="title">{{ language.Dashboard }}</span>
          </router-link>
          <router-link v-if="!isAdminRole" to="/Operatororder" class="Loader" @click="toggleMenuMd()">
            <span class="icon-holder">
              <i class="icon-hand-point-up"></i>
            </span>
            <span class="title">{{ language.AllOrder }}</span>
          </router-link>
        </li>
        <li v-if="isAdminRole" class="nav-item active">
          <router-link to="/Category" class="Loader" @click="toggleMenuMd()">
            <span class="icon-holder">
              <i class="icon-network-wired"></i>
            </span>
            <span class="title">{{ language.Category }}</span>
          </router-link>
        </li>
        <li class="nav-item active">
          <router-link to="/User" class="Loader" @click="toggleMenuMd()">
            <span class="icon-holder">
              <i class="icon-users"></i>
            </span>
            <span class="title">{{ language.User }}</span>
          </router-link>
        </li>
         <li v-if="isAdminRole" class="nav-item active">
          <router-link to="/Provider" class="Loader" @click="toggleMenuMd()">
            <span class="icon-holder">
              <i class="icon-building"></i>
            </span>
            <span class="title">{{ language.Provider }}</span>
          </router-link>
        </li>
        <li v-if="isAdminRole" class="nav-item active">
          <router-link to="/Hotel" class="Loader" @click="toggleMenuMd()">
            <span class="icon-holder">
              <i class="icon-building"></i>
            </span>
            <span class="title">{{ language.Hotels }}</span>
          </router-link>
        </li>
         <li v-if="isAdminRole" class="nav-item active">
          <router-link to="/Operator" class="Loader" @click="toggleMenuMd()">
            <span class="icon-holder">
              <i class="icon-user-tie"></i>
            </span>
            <span class="title">{{ language.Employee }}</span>
          </router-link>
        </li>
        <li v-if="isAdminRole" class="nav-item active">
          <router-link to="/Order" class="Loader" @click="toggleMenuMd()">
            <span class="icon-holder">
              <i class="icon-hand-point-up"></i>
            </span>
            <span class="title">{{ language.AllOrder }}</span>
          </router-link>
        </li>
        <li v-if="isAdminRole" class="nav-item active">
          <router-link to="/Operator" class="Loader" @click="toggleMenuMd()">
            <span class="icon-holder">
              <i class="icon-user-tie"></i>
            </span>
            <span class="title">{{ language.Employee }}</span>
          </router-link>
          <router-link
            to="/FreindlyPack"
            class="Loader"
            @click="toggleMenuMd()"
          >
            <span class="icon-holder">
              <i class="icon-boxes"></i>
            </span>
            <span class="title">{{ language.FreindlyPack }}</span>
          </router-link>
        </li>
        <li v-if="isAdminRole" class="nav-item active">
          <router-link to="/SignupAct" class="Loader" @click="toggleMenuMd()">
            <span class="icon-holder">
              <i class="icon-file-alt"></i>
            </span>
            <span class="title">{{ language.SignupAct }}</span>
          </router-link>
        </li>
        <li v-if="isAdminRole" class="nav-item active">
          <router-link to="/Wallet" class="Loader" @click="toggleMenuMd()">
            <span class="icon-holder">
              <i class="icon-university"></i>
            </span>
            <span class="title">{{ language.Bank }}</span>
          </router-link>
        </li>
         <li v-if="isAdminRole" class="nav-item active">
          <router-link to="/Notification" class="Loader" @click="toggleMenuMd()">
            <span class="icon-holder">
              <i class="icon-comment-alt"></i>
            </span>
            <span class="title">{{ language.Notification }}</span>
          </router-link>
        </li>
        <li v-if="isAdminRole" class="nav-item active">
          <router-link to="/Slider" class="Loader" @click="toggleMenuMd()">
            <span class="icon-holder">
              <i class="icon-images"></i>
            </span>
            <span class="title">{{ language.Slider }}</span>
          </router-link>
        </li>
        <li v-if="isAdminRole" class="nav-item active">
          <router-link to="/Config" class="Loader" @click="toggleMenuMd()">
            <span class="icon-holder">
              <i class="icon-info"></i>
            </span>
            <span class="title">{{ language.Config }}</span>
          </router-link>
        </li>
       
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mobile: window.innerWidth <= 991,
      HasLogin: false,
    };
  },
  mounted() {
    this.HasLogin = localStorage.getItem("token") != null;
  },
  methods: {
    toggleMenu() {
      this.$emit("toggleEvent");
    },
    toggleMenuMd() {
      if (this.mobile == true) {
        this.$emit("toggleEvent");
      }
    },
  },
};
</script>

