<template>
  <div class="modal show">
    <div
      class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
      role="document"
    >
      <div id="modal-content" class="modal-content">
        <div class="modal-header">
          <h6 v-if="id == 0" class="modal-title">{{ language.Create }}</h6>
          <h6 v-if="id != 0" class="modal-title">{{ language.Edit }}</h6>
          <button
            @click="closeModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-row">
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">{{
                    language.faCategoryName
                  }}</label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="name"
                      :placeholder="language.faCategoryName"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">{{
                    language.TrCategoryName
                  }}</label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="trName"
                      :placeholder="language.TrCategoryName"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-2 col-form-label">{{
                    language.IsReservesion
                  }}</label>
                  <div class="form-group col-lg-3">
                    <input
                      type="checkbox"
                      class="form-control"
                      :checked="isReservetion"
                      @change="changeReservetion()"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-2 col-form-label">{{
                    language.IsMenu
                  }}</label>
                  <div class="form-group col-lg-3">
                    <input
                      type="checkbox"
                      class="form-control"
                      :checked="isMenu"
                      @change="changeMenu()"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-2 col-form-label">{{
                    language.IsRoom
                  }}</label>
                  <div class="form-group col-lg-3">
                    <input
                      type="checkbox"
                      class="form-control"
                      :checked="isRoom"
                      @change="changeRoom()"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="file-upload">
                  <div class="image-upload-wrap">
                    <input
                      class="file-upload-input"
                      type="file"
                      @change="readURL"
                      accept="image/*"
                    />
                    <div class="drag-text">
                      <img
                        v-if="PreviewImage == null"
                        src="../../../../public/assets/img/customupload.png"
                        alt=""
                        width="100"
                      />
                      <img
                        v-if="PreviewImage != null"
                        :src="PreviewImage"
                        alt=""
                        width="100"
                      />
                      <h3>Drag and drop a file or select add Image</h3>
                    </div>
                  </div>
                  <div class="file-upload-content">
                    <img
                      class="file-upload-image"
                      :src="ImageUrl"
                      alt="your image"
                    />
                    <div class="image-title-wrap">
                      <button type="button" class="remove-image">
                        Remove <span class="image-title">Uploaded Image</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                @click="submitCategory()"
                v-if="id == 0"
                class="btn btn-sm btn-success"
              >
                {{ language.Create }}
              </button>
              <button
                @click="submitCategory()"
                v-if="id != 0"
                class="btn btn-sm btn-success"
              >
                {{ language.Edit }}
              </button>
              <button
                @click="closeModal()"
                type="button"
                class="btn btn-sm btn-info"
                data-dismiss="modal"
              >
                {{ language.Close }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { closeModal } from "jenesius-vue-modal";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  Loading,
  CheckNetwork,
  ShowAlert,
  ReloadList,
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      v$: useValidate(),
      id: Number,
      name: String,
      trName: String,
      isReservetion: Boolean,
      File: null,
      PreviewImage: null,
      isMenu: Boolean,
      isRoom: Boolean,
    };
  },
  props: {
    idProp: Number,
    titleProp: String,
    trTitleProp: String,
    isReservetionProp: Boolean,
    ImageProp: String,
    isMenuProp: Boolean,
    isRoomProp: Boolean,
  },
  validations() {
    return {
      name: { required },
    };
  },
  methods: {
    closeModal() {
      closeModal();
    },
    changeReservetion() {
      this.isReservetion = !this.isReservetion;
    },
    changeMenu() {
      this.isMenu = !this.isMenu;
    },
    changeRoom() {
      this.isRoom = !this.isRoom;
    },
    submitCategory() {
      Loading(1);
      this.v$.$validate().then((res) => {
        if (res) {
          if (CheckNetwork()) {
            axios
              .post("/Category/CreateOrEditCategory", {
                id: this.id,
                name: this.name,
                trName: this.trName,
                isReservetion: this.isReservetion,
                isMenu: this.isMenu,
                isHotel: this.isRoom,
              })
              .then((res) => {
                if (res.data.isSuccess) {
                  if (this.File) {
                    var bodyFormData = new FormData();

                    bodyFormData.append("Id", res.data.result.id);
                    bodyFormData.append("File", this.File);
                    bodyFormData.append("FolderName", "Category");

                    axios
                      .post("/Category/UploadCategoryImage", bodyFormData, {
                        "content-type": "multipart/form-data",
                      })
                      .then((res) => {
                        if (!res.data.isSuccess) {
                          ShowAlert(
                            "success",
                            "",
                            this.language.CheckExtensionFileError
                          );
                        } else {
                          Loading(0);
                          closeModal();
                          ReloadList();
                          ShowAlert("success", "", this.language.success);
                        }
                      });
                  } else {
                    Loading(0);
                    closeModal();
                    ReloadList();
                    ShowAlert("success", "", this.language.success);
                  }
                } else {
                  Loading(0);
                  ShowAlert("danger", this.language.error, res.data.message);
                }
              })
              .catch((err) => console.log(err));
          } else {
            Loading(0);
            ShowAlert(
              "danger",
              this.language.error,
              this.language.Checkyourinternet
            );
          }
        } else {
          Loading(0);
          ShowAlert("danger", this.language.error, this.language.ValidateForm);
        }
      });
    },
    readURL(event) {
      this.File = event.target.files[0];
      this.PreviewImage = URL.createObjectURL(this.File);
    },
  },
  mounted() {
    this.id = this.idProp;
    this.name = this.titleProp;
    this.trName = this.trTitleProp;
    this.isReservetion = this.isReservetionProp;
    this.PreviewImage = this.ImageProp;
    this.isMenu = this.isMenuProp;
    this.isRoom=this.isRoomProp;
  },
};
</script>
