<template>
  <div id="card-table" class="card-body flex-div">
    <div class="table-responsive flex">
      <table class="table fixed">
        <thead class="thead-light">
          <tr>
            <th scope="col">{{language.Title}}</th>
               <th scope="col">{{language.image}}</th>
            <th scope="col">{{language.operations}}</th>
          </tr>
        </thead>
        <tbody>
          <slider-item
            v-for="item in Sliders"
            :key="item.id"
            :Slider="item"
          ></slider-item>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import SliderItem from "./SliderItem.vue";

export default {
  props:["Sliders"],
  components: {
    SliderItem,
    
  },
  mounted(){
   console.log(this.Sliders);
  }
 
};
</script>