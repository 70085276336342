<template>
  <div id="card-table" class="card-body flex-div">
    <div class="table-responsive flex">
      <table class="table fixed">
        <thead class="thead-light">
          <tr>
            <th scope="col">{{language.Id}}</th>
            <th scope="col">{{language.Title}}</th>
            <th scope="col">{{language.operations}}</th>
          </tr>
        </thead>
        <tbody>
          <category-item
            v-for="item in Categories"
            :key="item.id"
            :Category="item"
          ></category-item>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import CategoryItem from "./CategoryItem.vue";

export default {
  props:["Categories"],
  components: {
    CategoryItem,
    
  },
 
};
</script>