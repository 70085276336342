<template>
    <ul class="nav-left float-right">
        <li class="user-profile dropdown">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                <div class="user-info">

                    <i class="icon-globe pr-1"></i><span class="name pr-2">{{language.Language}}</span>

                    <i class="icon-chevron-down font-size-10"></i>
                </div>
            </a>
            <ul class="dropdown-menu">
   
                <li>
                    <a @click="Changelanguage('fa')">
                        <span class="mr-2"><img src="../../public/assets/img/faflag.png"  alt=""  ></span><span>{{language.Persian}}</span>
                    </a>
                </li>
                <li>
                    <a @click="Changelanguage('tr')">
                        <span class="mr-2"><img src="../../public/assets/img/trflag.png"  alt="" ></span><span>{{language.Turkish}}</span>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
import {
   Loading
} from "../../public/js/Utilities";
export default {
    data(){
        return{
            direction:""
        }
    },

    mounted() {

        this.direction=this.language.dir
        if (this.direction === 'rtl') {
              
              document.body.classList.add('rtl');
             
          }
          else if(this.direction === 'ltr'){
            
              document.body.classList.remove('rtl');
           
          }

    },
    methods: {
        Changelanguage(lan) {
            localStorage.setItem('language', lan);
            Loading(1);
            window.location.reload();
            Loading(0);
        

        },

    },

}
</script>


