<template>
  <div class="row flex-div">
    <div class="col-md-12 flex-div pb-3 pt-3">
      <div class="card flex-div border-0 main-shadow">
        <div class="card-header bg-white">
          <div class="row">
            <div class="col-12">
              <button
                  id="BtnSearch"
                  @click="Search()"
                  type="button"
                  class="btn btn-primary mx-3 mb-3 d-none"
                >
                  <i class="icon-search"></i>{{ language.Search }}
                </button>
              <a
                @click="Create()"
                class="
                  btn btn-success
                  mx-1
                  mb-1
                  float-lg-left float-none
                  text-white
                  Loader
                "
                ><i class="icon-plus"></i>{{ language.Create }}</a
              >
            </div>
          </div>
        </div>
        <div id="pat" class="flex-div">
          <slider-list :Sliders="list" v-if="HasList"></slider-list>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import SliderList from "./SliderList.vue";
import axios from "axios";
import { openModal } from "jenesius-vue-modal";
import SliderCreateOrEdit from "./SliderCreateOrEdit.vue";
import {
  Loading,
  TitleBar,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";

export default {
  components: {
    SliderList,
  },
  data() {
    return {
      list: [],
      HasList: false,
    };
  },
  mounted() {
    TitleBar(this.language.Slider);
    this.LoadData();
  },
  methods: {
    Search() {
      this.LoadData();
    },
    Create() {
      openModal(SliderCreateOrEdit, {
        idProp: 0,
        titleProp: null,
        DescriptionProp: false,
      });
    },
    Page(id) {
      this.Pageid = id;
      this.LoadData();
    },
    PageChev(i) {
      if (i === 1) {
        this.Pageid++;
      } else if (i === 0) {
        this.Pageid = this.Pageid - 1;
      }
      this.LoadData();
    },
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get(`/Slider/GetList`)
          .then((res) => {
            if (res.data.isSuccess) {
              if (res.data.result.length == 0) {
                this.HasList = false;
                Loading(0);
              } else {
                this.HasList = true;
                this.list = res.data.result;
                Loading(0);
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
  },
};
</script>