<template>
  <div class="modal show">
    <div
      class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
      role="document"
    >
      <div id="modal-content" class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">{{ language.Create }}</h6>
          <button
            @click="closeModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-row">
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">{{
                    language.Text
                  }}</label>
                  <div class="form-group col-lg-6">
                    <textarea
                      type="text"
                      class="form-control"
                      v-model="Text"
                      :placeholder="language.Text"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button @click="submit()" class="btn btn-sm btn-success">
                {{ language.Create }}
              </button>

              <button
                @click="closeModal()"
                type="button"
                class="btn btn-sm btn-info"
                data-dismiss="modal"
              >
                {{ language.Close }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { closeModal } from "jenesius-vue-modal";
import {
  Loading,
  ReloadList,
  CheckNetwork,
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      Text: "",
    };
  },
  methods: {
    closeModal() {
      closeModal();
    },
    submit() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .post("/Notification/CreateNotification", {
            id: 1,
            text: this.Text,
            userId: null,
            userFullName: null,
            createDate: null,
          })
          .then(() => {

            const options = {
              method: "POST",
              url: "https://onesignal.com/api/v1/notifications",
              headers: {
                accept: "application/json",
                Authorization: "Basic M2ZjOWZhZjEtMDViMS00ODM1LTg0ZmQtZTEyZGIwYjZmOGNm",
                "Content-Type": "application/json",
              },
              data: {
                included_segments: ["Subscribed Users"],
                contents: {
                  en: this.Text
                },
                name: this.Text,
                app_id: "37997245-c71d-43cd-849c-8b57affb97cf"
              },
            };

            axios
              .request(options)
              .then(function (response) {
                console.log(response.data);
              })
              .catch(function (error) {
                console.error(error);
              });

            Loading(0);
            this.closeModal();
            ReloadList();
          });
      }
    },
  },
};
</script>
