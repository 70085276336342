<template>
  <div class="modal show">
    <div
      class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable"
      role="document"
    >
      <div id="modal-content" class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">
            {{ language.Room }}
          </h6>
          <button
            @click="closeModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4 mb-4">
              <div class="card">
                <div class="card-header">
                  {{ language.Create }} {{ language.Room }}
                </div>
                <div class="card-body">
                  <div class="form-group form-row">
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="language.BedCount"
                        v-model="BedCount"
                      />
                    </div>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="language.Amount"
                        v-model="Price"
                      />
                    </div>
                    <br />
                    <br />
                    <div class="col-12">
                      <div class="row">
                        <label class="col-lg-4 col-form-label">{{
                          language.IsBreakfast
                        }}</label>
                        <div class="form-group col-lg-6">
                          <input
                            type="checkbox"
                            class="form-control"
                            :checked="IsBreakfast"
                            v-model="IsBreakfast"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    class="btn btn-primary"
                    type="submit"
                    @click="submitRoom()"
                  >
                    {{ language.Create }}
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-8 mb-4">
              <div class="card">
                <div class="card-header">{{ language.ListOfRoom }}</div>
                <div class="card-body">
                  <div id="card-table" class="card-body flex-div">
                    <div class="table-responsive flex">
                      <table class="table fixed table-time">
                        <thead class="thead-light">
                          <tr>
                            <th scope="col">{{ language.BedCount }}</th>
                            <th scope="col">{{ language.Amount }}</th>
                            <th scope="col">{{ language.IsBreakfast }}</th>
                            <th scope="col">{{ language.operations }}</th>
                          </tr>
                        </thead>
                        <tbody v-if="HasList">
                          <tr
                            :id="'RoomId-' + Room.id"
                            v-for="Room in list"
                            :key="Room.id"
                          >
                            <td>{{ Room.bedCount }}</td>
                            <td>{{ Room.price }}</td>
                            <td>
                              <input
                                v-model="Room.isBreakfast"
                                disabled="disabled"
                                type="checkbox"
                              />
                            </td>
                            <td class="text-center">
                              <span
                                v-on:dblclick="Delete(Room.id)"
                                class="icon icon-trash text-danger"
                              ></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { closeModal } from "jenesius-vue-modal";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  Loading,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      v$: useValidate(),
      BedCount: "",
      Price: "",
      IsBreakfast: false,
      ProviderId: 0,
      list: [],
      HasList: false,
    };
  },
  validations() {
    return {
      BedCount: { required },
      Price: { required },
    };
  },

  props: {
    IdProp: Number,
  },
  mounted() {
    this.LoadData();
  },
  methods: {
    Page(id) {
      this.Pageid = id;
      this.LoadData();
    },
    closeModal() {
      closeModal();
    },
    Delete(id) {
      document.getElementById(`RoomId-${id}`).style.display = "none";
      ShowAlert("danger", "", this.language.Deleted);
      axios.post(`/ProviderRoom/DeleteById?id=${id}`);
    },
    submitRoom() {
      Loading(1);
      this.v$.$validate().then((res) => {
        if (res) {
          if (CheckNetwork()) {
            axios
              .post("/ProviderRoom/CreateOrEditProviderRoom", {
                id: null,
                bedCount: this.BedCount,
                price: this.Price,
                isBreakfast: this.IsBreakfast,
                providerId: this.IdProp,
              })
              .then(() => {
                Loading(0);
                closeModal();
                ShowAlert("success", "✓", this.language.success);
              })
              .catch((err) => console.log(err));
          } else {
            Loading(0);
            ShowAlert(
              "danger",
              this.language.error,
              this.language.Checkyourinternet
            );
          }
        } else {
          Loading(0);
          ShowAlert("danger", this.language.error, this.language.ValidateForm);
        }
      });
    },
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get(`/ProviderRoom/GetByProviderIdlist?ProviderId=${this.IdProp}`)
          .then((res) => {
            if (res.data.isSuccess) {
              if (res.data.result.length == 0) {
                this.HasList = false;
                Loading(0);
              } else {
                this.HasList = true;
                this.PageCount = res.data.result.pageCount;
                this.list = res.data.result;

                Loading(0);
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
  },
};
</script>