<template><div class="modal show">
    <div
      class="
        modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable
      "
      role="document"
    >
      <div id="modal-content" class="modal-content">
        <div class="modal-header d-flex align-items-center">
            <h4 class="modal-title">{{ language.Description }} {{ language.Wallet }}</h4>
            <button
            @click="closeModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{ text }}</p>
        </div>
      </div>
    </div>
  </div></template>
<script>
import { closeModal } from "jenesius-vue-modal";
export default {
  data() {
    return {
      text: null,
    };
  },
  props: {
    textProp: String,
  },
  mounted() {
    this.text = this.textProp;
  },
  methods: {
    closeModal() {
      closeModal();
    },
  },
};
</script>
