<template>
  <div class="row flex-div">
    <div class="col-md-12 flex-div pb-3 pt-3">
      <div class="card flex-div border-0 main-shadow">
        <div class="card-header bg-white">
          <div class="row">
            <div>
              <a
                class="btn btn-success mx-1 mb-1 float-lg-right float-none text-white"
                @click="UpdateAllConfig()"
                ><i class="icon-sync"></i>{{ language.Update }}</a
              >
            </div>
          </div>
        </div>
        <div id="pat" class="flex-div">
          <div id="card-table" class="card-body flex-div">
            <div class="table-responsive flex">
              <table class="table fixed">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">{{ language.Title }}</th>
                    <th scope="col">{{ language.Key }}</th>
                    <th scope="col">{{ language.Text }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ language.Text }} {{ language.ChargeWallet }}</td>
                    <td>ChargeWallet</td>
                    <td>
                      <textarea
                        class="form-control"
                        v-model="ChargeWallet"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ language.Text }} {{ language.Address }}</td>
                    <td>Address</td>
                    <td>
                      <textarea
                        class="form-control"
                        v-model="Address"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ language.Text }} {{ language.AboutUs }}</td>
                    <td>AboutUs</td>
                    <td>
                      <textarea
                        class="form-control"
                        v-model="AboutUs"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ language.Text }} {{ language.ContactUs }}</td>
                    <td>ContactUs</td>
                    <td>
                      <textarea
                        class="form-control"
                        v-model="ContactUs"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ language.Text }} {{ language.Privacy }}</td>
                    <td>Privacy</td>
                    <td>
                      <textarea
                        class="form-control"
                        v-model="Privacy"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ language.Text }} {{ language.AgenciesAddress }}</td>
                    <td>AgenciesAddress</td>
                    <td>
                      <textarea
                        class="form-control"
                        v-model="AgenciesAddress"
                      ></textarea>
                    </td>
                  </tr>

                  <tr>
                    <td> {{ language.WhatsAppAccount }}</td>
                    <td>WhatsAppAccount</td>
                    <td>
                      <textarea
                        class="form-control"
                        v-model="WhatsAppAccount"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td> {{ language.Gift }}</td>
                    <td>Gift</td>
                    <td>
                      <input
                        class="form-control"
                        v-model="Gift"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card-footer bg-white"></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import {
  Loading,
  TitleBar,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";

export default {
  components: {},
  data() {
    return {
      ChargeWallet: "",
      Address: "",
      AboutUs: "",
      ContactUs: "",
      Privacy: "",
      AgenciesAddress: "",
      WhatsAppAccount: "",
      Gift:""
    };
  },
  mounted() {
    TitleBar(this.language.Config);
    this.LoadData();
  },
  methods: {
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get(`/Config/GetAll`)
          .then((res) => {
            if (res.data.isSuccess) {
              this.ChargeWallet = res.data.result.chargeWallet;
              this.Address = res.data.result.address;
              this.AboutUs = res.data.result.aboutUs;
              this.ContactUs = res.data.result.contactUs;
              this.Privacy = res.data.result.privacy;
              this.AgenciesAddress = res.data.result.agenciesAddress;
              this.WhatsAppAccount = res.data.result.whatsAppAccount;
              this.Gift=res.data.result.gift;
              Loading(0);
            }
          })
          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
    UpdateAllConfig() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .post(`/Config/Update`, {
            chargeWallet: this.ChargeWallet,
            address: this.Address,
            aboutUs: this.AboutUs,
            contactUs: this.ContactUs,
            privacy: this.Privacy,
            agenciesAddress: this.AgenciesAddress,
            whatsAppAccount: this.WhatsAppAccount,
            gift:this.Gift
          })
          .then(() => {
            Loading(0);
            ShowAlert("success", "", this.language.success);
          })
          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
  },
};
</script>