<template>
  <tr>
    <td>
      {{ Provider.id }}
    </td>

    <td v-if="languae == 'fa'">
      {{ Provider.shopName }}
    </td>
    <td v-if="languae == 'tr'">
      {{ Provider.trShopName }}
    </td>

    <td v-if="languae == 'fa'">
      {{ Provider.categoryName }}
    </td>
    <td v-if="languae == 'tr'">
      {{ Provider.trCategoryName }}
    </td>

    <td>
      {{ Provider.sumUnCheckout }}
    </td>
    <td>
      {{ Provider.sumUnCheckoutSite }}
    </td>
    <td>
      {{ Provider.sumUnCheckoutShop }}
    </td>
    <td>
      <span
        class="ml-2"
        data-placement="top"
        data-toggle="tooltip"
        title=""
        data-original-title=""
      >
        <button
          :id="'isChosen-' + Provider.id"
          v-if="Provider.isChosen"
          @click="IsChosen(Provider.id)"
          class="badge badge-success px-3 py-1"
        >
          <i
            :id="'isChoseni-' + Provider.id"
            class="btn icon-star p-0 loader text-white m-0"
          ></i>
        </button>
        <button
          :id="'isChosen-' + Provider.id"
          v-if="!Provider.isChosen"
          @click="IsChosen(Provider.id)"
          class="badge badge-danger px-3 py-1"
        >
          <i
            :id="'isChoseni-' + Provider.id"
            class="btn icon-star-half p-0 loader text-white m-0"
          ></i>
        </button>
      </span>
      <span
        class="ml-2"
        data-placement="top"
        data-toggle="tooltip"
        title=""
        data-original-title=""
      >
        <button @click="Edit()" class="badge badge-info px-3 py-1">
          <i class="btn icon-Edit p-0 loader text-white m-0"></i>
        </button>
      </span>
      <span
        class="ml-2"
        data-placement="top"
        data-toggle="tooltip"
        title=""
        data-original-title=""
      >
        <button
          v-if="Provider.isDiscount"
          @click="isDiscount_btn(Provider.id)"
          class="badge badge-success px-3 py-1"
          :id="'discount-btn' + Provider.id"
        >
          <i
            :id="'discount-btni' + Provider.id"
            class="btn icon-percent p-0 loader text-white m-0"
          ></i>
        </button>
        <button
          v-if="!Provider.isDiscount"
          @click="isDiscount_btn(Provider.id)"
          class="badge badge-danger px-3 py-1"
          :id="'discount-btn' + Provider.id"
        >
          <i
            :id="'discount-btni' + Provider.id"
            class="btn icon-percent p-0 loader text-white m-0"
          ></i>
        </button>
      </span>
      <span
        data-placement="top"
        data-toggle="tooltip"
        title=""
        data-original-title=""
      >
        <!-- <router-link to="/providerpanel/Provider.id" class="badge badge-info px-3 py-1">
          <i class="btn icon-user-circle p-0 loader text-white m-0"></i>
        </router-link> -->

        <button
          class="badge badge-info px-3 py-1"
          @click="gotoPanel(Provider.id)"
        >
          <span>
            <i class="btn icon-user-circle p-0 loader text-white m-0"></i>
          </span>
        </button>
      </span>

      <button @click="UserInfo()" class="badge badge-info px-3 py-1 mr-2">
        {{ language.UserInfo }}
      </button>
    </td>
  </tr>
</template>
<script>
import { openModal } from "jenesius-vue-modal";
import ProviderCreateOrEdit from "../provider/ProviderCreateOrEdit.vue";
import UserCreateOrEdit from "../user/UserCreateOrEdit.vue";
import axios from "axios";
import {
  Loading
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      isChosen: this.Provider.isChosen,
      languae: localStorage.getItem("language") == "tr" ? "tr" : "fa",
      isDiscount: this.Provider.isDiscount,
    };
  },
  mounted() {},
  props: ["Provider"],
  methods: {
    isDiscount_btn(id) {
      axios.post(`/Provider/ChangeIsDiscountProvider?id=${this.Provider.id}`);
      if (this.isDiscount) {
        document
          .getElementById(`discount-btn${id}`)
          .classList.remove("badge-success");
        document
          .getElementById(`discount-btn${id}`)
          .classList.add("badge-danger");
        this.isDiscount = false;
      } else {
        document
          .getElementById(`discount-btn${id}`)
          .classList.remove("badge-danger");
        document
          .getElementById(`discount-btn${id}`)
          .classList.add("badge-success");
        this.isDiscount = true;
      }
    },
    gotoPanel(id) {
      this.$router.push(`hotelpanel/${id}`);
    },
    Edit() {
      //Edit Provider
      openModal(ProviderCreateOrEdit, {
        IdProp: this.Provider.id,
        UserIdProp: 0,
      });
    },
    IsChosen(id) {
      axios.post(`/Provider/ChangeIsChosenProvider?id=${this.Provider.id}`);
      if (this.isChosen) {
        document
          .getElementById(`isChosen-${id}`)
          .classList.remove("badge-success");
        document.getElementById(`isChosen-${id}`).classList.add("badge-danger");

        document
          .getElementById(`isChoseni-${id}`)
          .classList.remove("icon-star");
        document
          .getElementById(`isChoseni-${id}`)
          .classList.add("icon-star-half");
        this.isChosen = false;
      } else {
        document
          .getElementById(`isChosen-${id}`)
          .classList.add("badge-success");
        document
          .getElementById(`isChosen-${id}`)
          .classList.remove("badge-danger");

        document.getElementById(`isChoseni-${id}`).classList.add("icon-star");
        document
          .getElementById(`isChoseni-${id}`)
          .classList.remove("icon-star-half");
        this.isChosen = true;
      }
    },
    UserInfo() {
      Loading(1);
      axios.get(`/User/GetById?id=${this.Provider.userId}`).then((res) => {
        openModal(UserCreateOrEdit, {
          idProp: res.data.result.id,
          TelNuProp: res.data.result.telNu,
          EmailProp: res.data.result.email,
          PassportNuProp: res.data.result.passportNu,
          PasswordProp: "",
          GenderProp: res.data.result.gender,
          WhatsappAccountProp: res.data.result.whatsappAccount,
          ResidenceProp: res.data.result.residence,
          FullNameProp: res.data.result.fullName,
          IsProviderProp: false,
        });
        Loading(0);
      });
    },
  },
};
</script>
