<template>
  <tr>
    <td>
      {{ Order.id }}
    </td>
    <td>
      {{ Order.userName }}
    </td>
    <td>
      {{ Order.providerShopName }}
    </td>
    <td>
      {{ Order.amount }}
    </td>

    <td>
      <span v-if="Order.isCheckout" class="text-success">{{
        language.IsCheckout
      }}</span>
      <span v-if="!Order.isCheckout" class="text-danger">
        {{ language.UnIsCheckout }}
      </span>
    </td>
 <td>
   <span v-if="Order.operatorUserName==null" class="text-success"></span>
      <span v-if="Order.operatorUserName!=null && Order.operatorIsCheckout" class="text-success">{{
        language.IsCheckout
      }} - {{ Order.operatorUserName }}</span>
      <span v-if="Order.operatorUserName!=null && !Order.operatorIsCheckout" class="text-danger">
        {{ language.UnIsCheckout }} - ({{ Order.operatorUserName }})
      </span>
    </td>
    <td>
      {{ Order.dateCreate }}
    </td>

    <td>
      {{ Order.goDate }}
    </td>

   
    <td>
      <span
        data-placement="top"
        data-toggle="tooltip"
        title=""
        data-original-title=""
      >
        <button @click="Info()" class="badge badge-info px-3 py-1"><i class="btn icon-info p-0 loader text-white m-0"></i>
        </button>
      </span>
    </td>
  </tr>
</template>
<script>
import { openModal } from "jenesius-vue-modal";
import OperatorOrderInfo from "./OperatororderInfo.vue";
export default {
  props: ["Order"],
  data() {
    return {
      id: 0,
      userId: 0,
      userName: "",
      providerId: 0,
      providerShopName: "string",
      amount: 0,
      isCehckout: Boolean,
      dateCreate: "",
    };
  },
  mounted() {
    this.id = this.Order.id;
    this.userId = this.Order.userId;
    this.userName = this.Order.userName;
    this.providerId = this.Order.providerId;
    this.providerShopName = this.Order.providerShopName;
    this.amount = this.Order.amount;
    this.isCehckout = this.Order.isCehckout;
    this.dateCreate = this.Order.dateCreate;
  },
  methods: {
    Info() {
      openModal(OperatorOrderInfo, {
        idProp: this.Order.id,
      });
    },
  },
};
</script>