<template>
  <div class="row flex-div">
    <div class="col-md-12 flex-div pb-3 pt-3">
      <div class="card flex-div border-0 main-shadow">
        <div class="card-header bg-white">
          <div class="row">
            <div class="col-lg-10 col-12">
              <div class="form-inline">
                <button @click="Create()" class="btn btn-success">
                  {{ language.Create }}
                </button>
                <button
                  id="BtnSearch"
                  @click="Search()"
                  type="button"
                  class="d-none"
                >
                  <i class="icon-search"></i>{{ language.Search }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="pat" class="flex-div">
          <div id="card-table" class="card-body flex-div">
            <div class="table-responsive flex">
              <table class="table fixed">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">{{ language.Id }}</th>
                    <th scope="col">{{ language.DateCreate }}</th>
                    <th scope="col">{{ language.Text }}</th>
                  </tr>
                </thead>
                <tbody v-if="HasList">
                  <tr v-for="Notification in list" :key="Notification.id">
                    <td>{{ Notification.id }}</td>
                    <td>{{ Notification.createDate }}</td>
                    <td>
                      <small>{{ Notification.text }}</small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer bg-white">
            <nav aria-label="Page navigation" class="float-left">
              <ul class="pagination pagination-sm">
                <li v-if="Pageid > 1" class="page-item">
                  <a
                    aria-label="Previous"
                    @click="PageChev(0)"
                    class="page-link"
                    ><span aria-hidden="true"
                      ><i class="icon-chevron-right m-0"></i></span
                  ></a>
                </li>
                <li
                  v-for="index in PageCount"
                  :key="index"
                  :class="{ active: Pageid == index }"
                  class="page-item"
                >
                  <a @click="Page(index)" class="page-link"
                    >{{ index }}<span class="sr-only"></span
                  ></a>
                </li>

                <li v-if="PageCount + 1 > Pageid" class="page-item">
                  <a aria-label="Next" @click="PageChev(1)" class="page-link"
                    ><span aria-hidden="true"
                      ><i class="icon-chevron-left m-0"></i></span
                  ></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateNotification from "./CreateNotification.vue";
import { openModal } from "jenesius-vue-modal";
import axios from "axios";
import {
  Loading,
  TitleBar,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      PageCount: 1,
      Pageid: 1,
      list: [],
      HasList: false,
    };
  },
  mounted() {
    TitleBar(this.language.Notification);
    this.LoadData();
  },
  methods: {
    Search() {
      this.LoadData();
    },
    Page(id) {
      this.Pageid = id;
      this.LoadData();
    },
    PageChev(i) {
      if (i === 1) {
        this.Pageid++;
      } else if (i === 0) {
        this.Pageid = this.Pageid - 1;
      }
      this.LoadData();
    },
    Create() {
      openModal(CreateNotification);
    },
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get("/Notification/GetList", {
            params: {
              PageId: this.Pageid,
            },
          })
          .then((res) => {
            if (res.data.isSuccess) {
              if (res.data.result.list.length > 0) {
                this.HasList = true;
                this.list = res.data.result.list;
              }
              Loading(0);
            }
          });
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
  },
};
</script>

