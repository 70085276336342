<template>
  <div class="modal show">
    <div
      class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
      role="document"
    >
      <div id="modal-content" class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">{{ language.Edit }}</h6>
          <button
            @click="closeModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="saved-images" v-for="item in previewImages" :key="item">
            <div v-if="!IsVideo(item.fileUrl)">
              <img :src="item.fileUrl" width="100" />
            </div>
            <div v-if="IsVideo(item.fileUrl)">
              <video
                :src="item.fileUrl"
                controls
                autoplay="autoplay"
                width="100"
              ></video>
            </div>
            <button @click="removeImage(item.id)" class="remove-btn">
              {{ language.Delete }}
            </button>
          </div>

          <hr />
          <div class="file-upload">
            <label for="file-input" class="file-label">
              <i class="fas fa-cloud-upload-alt"></i> {{ language.SelectFiles }}
            </label>
            <input
              id="file-input"
              type="file"
              ref="fileInput"
              multiple
              @change="onFileChange"
            />
            <div v-if="previewImages1.length > 0" class="preview-images">
              <h3>{{ language.Preview }}</h3>
              <div
                v-for="item in previewImages1"
                :key="item.index"
                class="preview-image"
              >
                <div v-if="!(item.type == 'video/mp4')">
                  <img :src="item.url" />
                </div>
                <div v-if="item.type == 'video/mp4'">
                  <video :src="item.url" controls></video>
                </div>
                <button @click="removeImage1(item.index)" class="remove-btn">
                  {{ language.Delete }}
                </button>
              </div>
            </div>
            <button @click="uploadSelectedFiles" class="upload-button">
              {{ language.UploadFiles }}
            </button>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="closeModal()"
            type="button"
            class="btn btn-sm btn-info"
            data-dismiss="modal"
          >
            {{ language.Close }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { closeModal } from "jenesius-vue-modal";
import {
  Loading,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      previewImages: [],
      previewImages1: [],
      uploadfiles: [],
    };
  },
  props: {
    IdProp: Number,
  },
  mounted() {
    Loading(1);
    if (CheckNetwork()) {
      axios
        .get(`/ProviderSlider/GetByProviderIdList?providerid=${this.IdProp}`)
        .then((res) => {
          if (res.data.isSuccess) {
            this.previewImages = res.data.result;
          }
          Loading(0);
        })
        .catch((err) => console.log(err));
    }
  },
  methods: {
    removeImage(id) {
      this.previewImages = this.previewImages.filter((p) => p.id != id);
      axios.post(`/ProviderSlider/Delete?id=${id}`);
    },
    removeImage1(index) {
      this.previewImages1.splice(index, 1);
    },
    closeModal() {
      closeModal();
    },
    onFileChange(event) {
      const files = event.target.files;
      this.uploadfiles = files;
      for (let i = 0; i < files.length; i++) {
        const type = files[i].type;
        const file = files[i];
        const url = URL.createObjectURL(file);
        const object = { type: type, url: url };
        this.previewImages1.push(object);
        console.log(this.previewImages1);
      }
    },
    uploadSelectedFiles() {
      const formData = new FormData();
      // for (let i = 0; i < this.previewImages1.length; i++) {
      //   formData.append("File", this.previewImages1[i]);
      // }
      for (let index = 0; index < this.uploadfiles.length; index++) {
        formData.append("File", this.uploadfiles[index]);
      }

      formData.append("Id", this.IdProp);
      formData.append("FolderName", "ProviderSlider");
      axios
        .post("/ProviderSlider/UploadProviderSlider", formData, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => {
          if (!res.data.isSuccess) {
            ShowAlert("danger", "", res.data.message);
          } else {
            ShowAlert("success", "", this.language.success);
          }
        });
    },
    IsVideo(link) {
      return link.split(".")[3] == "mp4";
    },
  },
};
</script>
<style>
.file-label {
  background-color: #3498db;
  color: #fff;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.file-label:hover {
  background-color: #2980b9;
}

#file-input {
  display: none;
}

.preview-images {
  margin-top: 10px;
}

.preview-image {
  display: inline-block;
  margin-bottom: 10px;
}

.preview-image img {
  max-width: 100%;
  max-height: 100px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.upload-button {
  background-color: #2ecc71;
  color: #fff;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 20px;
}

.upload-button:hover {
  background-color: #27ae60;
}

.remove-btn {
  top: 5px;
  right: 5px;
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
}
.remove-btn:hover {
  background-color: #d32f2f;
}

.saved-images {
  max-width: 100%;
  max-height: 100px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  display: inline-block;
  margin-right: 10px;
  margin-top: 20px;
  margin-right: 2rem;
}
</style>
