import { createRouter, createWebHistory } from 'vue-router'
//import store from './store'
import DashboardIndex from './components/pages/dashboard/DashboardIndex.vue'
import CategoeyIndex from './components/pages/category/CategoeyIndex.vue'
import UserIndex from './components/pages/user/UserIndex.vue'
import FreindlyPackIndex from './components/pages/freindlypack/FreindlyPackIndex.vue'
import SignIn from './components/pages/account/SignIn.vue'
import SignupAct from './components/pages/signupAct/SignupActIndex.vue'
import OrderIndex from './components/pages/order/OrderIndex.vue'
import ProviderIndex from './components/pages/provider/ProviderIndex.vue'
import ProviderPanel from './components/pages/provider/ProviderPanel.vue'
import CreateOrder from './components/pages/order/CreateOrder.vue'
import SliderIndex from './components/pages/slider/SliderIndex.vue'
import ConfigIndex from './components/pages/config/ConfigIndex.vue'
import ProviderMenuPanel from './components/pages/provider/ProviderMenuPanel.vue'
import OperatorIndex from './components/pages/Operator/OperatorIndex.vue'
import OperatororderIndex from './components/pages/operatororder/OperatororderIndex.vue'
import OperatorDashboard from './components/pages/dashboard/OperatorDashboard.vue'
import UserHistory from './components/pages/wallet/UserHistory.vue'
import WalletIndex from './components/pages/wallet/WalletIndex.vue'
import NotificationIndex from './components/pages/Notification/NotificationIndex.vue'
import HotelIndex from './components/pages/hotel/HotelIndex.vue'
import HotelPanel from './components/pages/hotel/HotelPanel'
const routes = [
    { path: "/", component: DashboardIndex, meta: { requiresAuth: true } },
    { path: "/:Categoey", component: CategoeyIndex, meta: { requiresAuth: true } },
    { path: "/User", component: UserIndex, meta: { requiresAuth: true } },
    { path: "/FreindlyPack", component: FreindlyPackIndex, meta: { requiresAuth: true } },
    { path: "/SignIn", component: SignIn },
    { path: "/SignupAct", component: SignupAct, meta: { requiresAuth: true } },
    { path: "/Order", component: OrderIndex, meta: { requiresAuth: true } },
    { path: "/Provider", component: ProviderIndex, meta: { requiresAuth: true } },
    // { path: "/ProviderPanel", component: ProviderPanel ,meta: { requiresAuth: true }},
    { path: "/ProviderPanel/:Provider", component: ProviderPanel, meta: { requiresAuth: true } },
    { path: "/CreateOrder/:User", component: CreateOrder, meta: { requiresAuth: true } },
    { path: "/Slider", component: SliderIndex, meta: { requiresAuth: true } },
    { path: "/Config", component: ConfigIndex, meta: { requiresAuth: true } },
    { path: "/ProviderMenuPanel/:Provider", component: ProviderMenuPanel, meta: { requiresAuth: true } },
    { path: "/Operator", component: OperatorIndex, meta: { requiresAuth: true } },
    { path: "/Operatororder", component: OperatororderIndex, meta: { requiresAuth: true } },
    { path: "/OperatorDashboard", component: OperatorDashboard, meta: { requiresAuth: true } },
    { path: "/wallet/UserHistory/:id", component: UserHistory, meta: { requiresAuth: true } },
    { path: "/wallet", component: WalletIndex, meta: { requiresAuth: true } },
    { path: "/Notification", component: NotificationIndex, meta: { requiresAuth: true } },
    { path: "/Hotel", component: HotelIndex, meta: { requiresAuth: true } },
    { path: "/HotelPanel/:Provider", component: HotelPanel, meta: { requiresAuth: true } },
]

const router = createRouter({
    history: createWebHistory(),
    routes,

})
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem("userinfo") != null) {
            next()
            return
        }
        next('/signin')
    } else {
        next()
    }
})

export default router;