<template>
  <div id="card-table" class="card-body flex-div">
    <div class="table-responsive flex">
      <table class="table fixed">
        <thead class="thead-light">
          <tr>
            <th scope="col">{{ language.Id }}</th>
            <th scope="col">{{ language.Username }}</th>
            <th scope="col">{{ language.TelNu }}</th>
            <th scope="col">{{ language.FullName }}</th>
            <th scope="col">{{ language.PassportNu }}</th>
            <th scope="col">{{ language.DateCreate }}</th>
            <th scope="col">{{ language.Gender }}</th>
            <th scope="col">{{ language.operations }}</th>
          </tr>
        </thead>
        <tbody>
          <user-item
            v-for="item in Users"
            :key="item.id"
            :User="item"
          ></user-item>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import UserItem from "./UserItem.vue";

export default {
  props: ["Users"],
  components: {
    UserItem,
  },
};
</script>