<template>
  <div class="col-md-12 flex-div pb-3 pt-3">
    <div class="card flex-div border-0 main-shadow">
      <div class="card-body flex h-100">
        <form class="h-100" @submit.prevent="login()" v-if="!HasLogin">
          <div class="row h-100 justify-content-center align-items-center">
            <div class="col-md-4">
              <div class="card shadow-10">
                <div class="card-body">
                  <div class="form-group">
                    <input class="form-control" v-model="userName" :placeholder="language.Username" />
                    <span class="text-danger erorr-m" asp-validation-for="TelNo"></span>
                  </div>
                  <div class="form-group">
                    <input type="password" class="form-control" v-model="password" :placeholder="language.Password" />
                    <span class="text-danger erorr-m" asp-validation-for="Password"></span>
                  </div>
                  <div class="form-group">
                    <input type="checkbox" class=" d-inline " v-model="IsEmployee" />
                    <label class="d-inline ml-3">{{ language.Employee }}</label>

                    <span class="text-danger erorr-m" asp-validation-for="Password"></span>
                  </div>
                </div>
                <div class="card-footer justify-content-end text-left">
                  <button type="submit" id="SubmiterLoad" class="btn btn-success btn-sm text-white">
                    <i class="icon-check"></i>{{ language.Sigin }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <span v-if="HasLogin">{{ language.HasLogin }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {
  Loading,
  TitleBar,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      userName: "",
      password: "",
      HasLogin: null,
      IsEmployee: false,
    };
  },
  mounted() {
    TitleBar(this.language.Signin);
    if (localStorage.getItem("token") != null) {
      this.HasLogin = true;
    } else {
      this.HasLogin = false;
    }
  },
  methods: {
    async login() {
      Loading(1);
      if (CheckNetwork()) {
        const response = await axios.post("/Account/Signin", {
          userName: this.userName,
          password: this.password,
          role: this.IsEmployee ? 3 : 4,
        });
        if (response.data.isSuccess) {
          localStorage.setItem("token", response.data.result.token);
          localStorage.setItem("userinfo", response.data.result.user.fullName);
          localStorage.setItem("Role", response.data.result.user.roleName);
          window.location.href = this.IsEmployee ? "/OperatorDashboard" : "/";

          Loading(0);
        } else {
          Loading(0);
          ShowAlert("danger", this.language.error, this.language.NotExistUser);
        }
      } else {
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
  },
};
</script>
