<template>
  <tr>
    <td>
      {{ Slider.title }}
    </td>
    <td>
      <img
        v-if="!IsVideo(Slider.image)"
        :src="Slider.image"
        style="height: 50px; width: 70px"
      />
      <video autoplay
        v-if="IsVideo(Slider.image)"
        :src="Slider.image"
        style="height: 50px; width: 70px"
      ></video>
    </td>
    <td>
      <span
        data-placement="top"
        data-toggle="tooltip"
        title=""
        data-original-title="ویرایش"
      >
        <a @click="Edit()"
          ><i class="btn icon-edit mr-2 text-primary p-0 loader"></i
        ></a>
      </span>
      <span
        data-placement="top"
        data-toggle="tooltip"
        title=""
        data-original-title="ویرایش"
      >
        <a @click="DeleteById()"
          ><i class="btn icon-trash mr-2 text-danger p-0 loader"></i
        ></a>
      </span>
    </td>
  </tr>
</template>
<script>
import { openModal } from "jenesius-vue-modal";
import SliderCreateOrEdit from "./SliderCreateOrEdit.vue";
import axios from "axios";
import { ShowAlert, ReloadList } from "../../../../public/js/Utilities.js";
export default {
  props: ["Slider"],
  methods: {
    Edit() {
      openModal(SliderCreateOrEdit, {
        idProp: this.Slider.id,
        titleProp: this.Slider.title,
        descriptionProp: this.Slider.description,
        ImageProp: this.Slider.image,
      });
    },
    DeleteById() {
      axios.post(`/Slider/DeleteById?id=${this.Slider.id}`).then(() => {
        ReloadList();
        ShowAlert("danger", "", this.language.Deleted);
      });
    },
    IsVideo(file) {
      return file.split(".")[3] == "mp4";
    },
  },
};
</script>