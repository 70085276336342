<template>
  <tr>
    <td>
      {{ Operator.id }}
    </td>
    <td>
      {{ Operator.fullName }}
    </td>
    <td>
      {{ Operator.userName }}
    </td>
    <td>
      {{ Operator.sumCreditor }}
    </td>
    <td>
      {{ Operator.sumDebtor }}
    </td>

    <td>
      <span
        data-placement="top"
        data-toggle="tooltip"
        title=""
        data-original-title=""
      >
        <button
          @click="BeforeoOperatorCheckout()"
          class="badge badge-info px-3 py-1"
        >
          {{ language.Checkouting }}
        </button>
      </span>
    </td>
  </tr>
</template>
<script>
import { openModal } from "jenesius-vue-modal";
import OperatorCheckout from "./OperatorCheckout.vue"
export default {
  props: ["Operator"],
  data() {
    return {
      id: 0,
      fullName: "",
      userName: 0,
      sumCreditor: 0,
      sumDebtor: 0,
    };
  },
  mounted() {
    this.id = this.Operator.id;
    this.fullName = this.Operator.fullName;
    this.userName = this.Operator.userName;
    this.sumCreditor = this.Operator.sumCreditor;
    this.sumDebtor = this.Operator.sumDebtor;
  },
  methods: {
    BeforeoOperatorCheckout() {
      openModal(OperatorCheckout, {
        IdProp: this.Operator.id,
      });
    },
  },
};
</script>