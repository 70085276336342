<template>
  <div class="modal show">
    <div
      class="
        modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable
      "
      role="document"
    >
      <div id="modal-content" class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">
            {{ language.BankCard }}
          </h6>
          <button
            @click="closeModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4 mb-4">
              <div class="card">
                <div class="card-header">
                  {{ language.Create }} {{ language.BankCard }}
                </div>
                <div class="card-body">
                  <div class="form-group form-row">
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="language.CardNu"
                        v-model="CardNu"
                      />
                    </div>
                    <div class="col">
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="language.BankName"
                        v-model="BankName"
                      />
                    </div>
                  </div>
                  <button
                    class="btn btn-primary"
                    type="submit"
                    @click="submitBankCard()"
                  >
                    {{ language.Create }}
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-8 mb-4">
              <div class="card">
                <div class="card-header">{{ language.ListOfBankCard }}</div>
                <div class="card-body">
                  <div id="card-table" class="card-body flex-div">
                    <div class="table-responsive flex">
                      <table class="table fixed table-time">
                        <thead class="thead-light">
                          <tr>
                            <th scope="col">{{ language.CardNu }}</th>
                            <th scope="col">{{ language.BankName }}</th>
                            <th scope="col">{{ language.IsActive }}</th>
                            <th scope="col">{{ language.operations }}</th>
                          </tr>
                        </thead>
                        <tbody v-if="HasList">
                          <tr
                            :id="'BankCardId-' + BankCard.id"
                            v-for="BankCard in list"
                            :key="BankCard.id"
                          >
                            <td>{{ BankCard.cardNu }}</td>
                            <td>{{ BankCard.bankName }}</td>
                            <td class="text-center">
                              <input
                                @change="ChangeIsActive(BankCard.id)"
                                type="radio"
                                name="B"
                                :checked="BankCard.isActive"
                              />
                            </td>

                            <td class="text-center">
                              <span
                                v-on:dblclick="Delete(BankCard.id)"
                                class="icon icon-trash text-danger"
                              ></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { closeModal } from "jenesius-vue-modal";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  Loading,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      v$: useValidate(),
      CardNu:"",
      BankName:"",
      ProviderId: 0,
      IsActive: false,
      list: [],
      HasList: false,
    };
  },
  validations() {
    return {
      CardNu: { required },
      BankName: { required },
    };
  },

  props: {
    IdProp: Number,
  },
  mounted() {
    this.LoadData();
  },
  methods: {
    Page(id) {
      this.Pageid = id;
      this.LoadData();
    },
    closeModal() {
      closeModal();
    },
    Delete(id) {
      document.getElementById(`BankCardId-${id}`).style.display = "none";
      ShowAlert("danger","", this.language.Deleted);
      axios.post(`/BankCard/DeleteById?id=${id}`);
    },
    ChangeIsActive(id) {
      axios.post(`/BankCard/ChangeIsActive?id=${id}`);
    },
    submitBankCard() {
      Loading(1);
      this.v$.$validate().then((res) => {
        if (res) {
          if (CheckNetwork()) {
            axios
              .post("/BankCard/CreateOrEditBankCard", {
                id:null,
                cardNu: this.CardNu,
                userId:this.IdProp,
                bankName:this.BankName
              })
              .then((res) => {
                if (res.data.isSuccess) {
                  Loading(0);
                  closeModal();
                  ShowAlert("success", "✓", this.language.success);
                } else {
                  Loading(0);
                  ShowAlert("danger", this.language.error, res.data.message);
                }
              })
              .catch((err) => console.log(err));
          } else {
            Loading(0);
            ShowAlert(
              "danger",
              this.language.error,
              this.language.Checkyourinternet
            );
          }
        } else {
          Loading(0);
          ShowAlert("danger", this.language.error, this.language.ValidateForm);
        }
      });
    },
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get(`/BankCard/GetByUserIdList?id=${this.IdProp}`)
          .then((res) => {
            if (res.data.isSuccess) {
              if (res.data.result.length == 0) {
                this.HasList = false;
                Loading(0);
              } else {
                this.HasList = true;
                this.PageCount = res.data.result.pageCount;
                this.list = res.data.result;

                Loading(0);
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
  },
};
</script>