<template>
  <div class="row flex-div">
    <div class="col-md-12 flex-div pb-3 pt-3">
      <div class="card flex-div border-0 main-shadow">
        <div class="card-header bg-white">
          <div class="row">
            <div class="col-lg-10 col-12">
              <div class="form-inline">
                <div class="form-group ml-3"></div>
              </div>
            </div>
            <button
                  id="BtnSearch"
                  @click="Search()"
                  type="button"
                  class="d-none"
                >
                  <i class="icon-search"></i>{{ language.Search }}
                </button>
            <div class="col-md-2 col-3">
              <a
                @click="Create()"
                class="
                  btn btn-success
                  mx-1
                  mb-1
                  float-lg-right float-none
                  text-white
                  Loader
                "
                ><i class="icon-plus"></i>{{ language.Create }}</a
              >
            </div>
          </div>
        </div>
        <div id="pat" class="flex-div">
          <freindlyPack-list
            :FreindlyPacks="list"
            v-if="HasList"
          ></freindlyPack-list>
          <div class="card-footer bg-white">
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
 import FreindlyPackList from "./FreindlyPackList.vue";
 import axios from "axios";
 import { openModal } from "jenesius-vue-modal";
 import FreindlyPackCreateOrEdit from "./FreindlyPackCreateOrEdit.vue";
import {
  Loading,
  TitleBar,
   CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";

export default {
    components: {
      FreindlyPackList,
    },
  data() {
    return {
      list: [],
      HasList: false,
    };
  },
  mounted() {
    TitleBar(this.language.FreindlyPack);
    this.LoadData();
  },
  methods: {
        Search() {
          this.LoadData();
        },
        Create() {
          openModal(FreindlyPackCreateOrEdit, {
            idProp: 0,
            expirationDayProp: null,
          });
        },
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get(`/FreindlyPack/GetList`)
          .then((res) => {
            if (res.data.isSuccess) {
              if (res.data.result.list.length == 0) {
                this.HasList = false;
                Loading(0);
              } else {
                this.HasList = true;
                this.PageCount = res.data.result.pageCount;
                this.list = res.data.result.list;
                Loading(0);
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
  },
};
</script>