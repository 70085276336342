<template>
    <div class="row flex-div">
      <div class="col-md-12 flex-div pb-3 pt-3">
        <div class="card flex-div border-0 main-shadow">
          <div class="card-header bg-white">
           
          </div>
          <div id="pat" class="flex-div">
            <signupAct-list :SignupActs="list" v-if="HasList"  ></signupAct-list>
            <div class="card-footer bg-white">
              <nav aria-label="Page navigation" class="float-left">
                <ul class="pagination pagination-sm">
                  <li v-if="Pageid > 1" class="page-item">
                    <a
                      aria-label="Previous"
                      @click="PageChev(0)"
                      class="page-link"
                      ><span aria-hidden="true"
                        ><i class="icon-chevron-right m-0"></i></span
                    ></a>
                  </li>
  
                  <li
                    v-for="index in PageCount"
                    :key="index"
                    :class="{ active: Pageid == index }"
                    class="page-item"
                  >
                    <a @click="Page(index)" class="page-link"
                      >{{ index }}<span class="sr-only"></span
                    ></a>
                  </li>
  
                  <li v-if="PageCount + 1 > Pageid" class="page-item">
                    <a aria-label="Next" @click="PageChev(1)" class="page-link"
                      ><span aria-hidden="true"
                        ><i class="icon-chevron-left m-0"></i></span
                    ></a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import SignupActList from "./SignupActList.vue";
  import axios from "axios";
 
  import {
    Loading,
    TitleBar,
    CheckNetwork,
    ShowAlert,
  } from "../../../../public/js/Utilities.js";
  
  export default {
    components: {
        SignupActList
    },
    data() {
      return {
        id: "",
        name: "",
        isRequired:Boolean,
        isUserName:Boolean,
        list: [],
        HasList: false,
        SearchName: "",
      };
    },
    mounted() {
      TitleBar(this.language.SignupAct);
      this.LoadData();
      
    },
    methods: {
      Search() {
        this.LoadData();
      },
    
      Page(id) {
        this.Pageid = id;
        this.LoadData();
      },
      PageChev(i) {
        if (i === 1) {
          this.Pageid++;
        } else if (i === 0) {
          this.Pageid = this.Pageid - 1;
        }
        this.LoadData();
      },
      LoadData() {
        Loading(1);
        if (CheckNetwork()) {
          axios
            .get(`/SignupAct/GetList`, 
             
            )
            .then((res) => {
              if (res.data.isSuccess) {
                if (res.data.result.length == 0) {
                  this.HasList = false;
                  Loading(0);
                } else {
                  this.HasList = true;
                  this.PageCount = res.data.result.pageCount;
                  this.list = res.data.result;
                  Loading(0);
                }
              }
            })
            .catch((err) => console.log(err));
        } else {
          Loading(0);
          ShowAlert(
            "danger",
            this.language.error,
            this.language.Checkyourinternet
          );
        }
      },
 
   
    },
  };
  </script>