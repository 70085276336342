<template>
  <div class="wrapper flex-div" :class="{ 'is-collapsed': isCollaps }">
    <div class="layout flex-div">
      <layout-sidebar @toggleEvent="sideNavToggle()"></layout-sidebar>
      <!-- Side Nav END -->
      <!-- Page Container START -->
      <div class="page-container flex-div">
        <!-- Header START -->
        <div class="header navbar">
          <div class="header-container">
            <ul class="nav-right float-left mr-3">
              <li>
                <a
                  class="side-nav-toggle"
                  href="javascript:void(0);"
                  @click="sideNavToggle"
                >
                  <i v-if="direction == 'rtl'" class="icon-align-right"></i>
                  <i v-if="direction == 'ltr'" class="icon-align-left"></i>
                </a>
              </li>
            </ul>

            <language-bar></language-bar>

            <ul class="nav-left float-right">
              <li class="user-profile dropdown">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                  <div class="user-info">
                    <i class="icon-user pr-1"></i
                    ><span class="name pr-2">{{ userName }}</span>

                    <i class="icon-chevron-down font-size-10"></i>
                  </div>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a @click="Logout()">
                      <i class="icon-power-off pr-1"></i>
                      <span>{{ language.Logout }}</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="sub-header">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb rounded-0 m-0 bg-white">
              <li class="breadcrumb-item p-0">
                <router-link to="/" v-if="isAdminRole">{{ language.Dashboard }}</router-link>
                <router-link to="/OperatorDashboard" v-if="!isAdminRole">{{ language.Dashboard }}</router-link>
              </li>
              <li
                id="breadcrumb"
                class="breadcrumb-item p-0 active"
                aria-current="page"
              ></li>
            </ol>
          </nav>
        </div>

        <div class="main-content flex">
          <div class="container-fluid flex-div">
            <router-view></router-view>
          </div>
        </div>

        <!-- Content Wrapper END -->
        <!-- Footer START -->
        <footer class="content-footer bg-white">
          <div class="footer border-0">
            <div class="copyright">
              <span><b class="text-dark">Reservesion</b> 2022</span>
            </div>
          </div>
        </footer>
        <!-- Footer END -->
      </div>
      <!-- Page Container END -->
    </div>
  </div>

  <widget-container-modal />

  <div id="overlay">
    <div class="cv-spinner">
      <span class="spinner"></span>
    </div>
  </div>
</template>

<script>
import LayoutSidebar from "./components/layout/LayoutSidebar.vue";
import LanguageBar from "./components/LanguageBar.vue";
import { container } from "jenesius-vue-modal";
export default {
  name: "App",
  data() {
    return {
      isCollaps: false,
      direction: "",
      userName: "",
    };
  },
  components: {
    LayoutSidebar,
    LanguageBar,
    WidgetContainerModal: container,
  },
  watch: {
    $route(to) {
      document.Title = to.meta.title || "sdfjlsdsjk";
    },
  },

  mounted() {
    this.direction = this.language.dir;
    this.userName = localStorage.getItem("userinfo");
  },
  methods: {
    sideNavToggle() {
      this.isCollaps = !this.isCollaps;
    },
    Logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("userinfo");
      window.location.href = "/";
    },
  },
};
</script>

