<template>
  <div class="row flex-div">
    <div class="col-md-12 flex pb-3 pt-3">
      <div class="form-inline">
        <div class="form-group mb-2">
          <label class="mr-2">{{ language.StartDate }}</label>
          <datepicker v-model="startDate"></datepicker>
        </div>
        <div class="form-group mx-sm-3 mb-2">
          <label class="mr-2">{{ language.EndDate }}</label>
          <datepicker v-model="endDate"></datepicker>
        </div>
        <button type="submit" class="btn btn-primary mb-2" @click="Search()">
          <i class="icon-search"></i>{{ language.Search }}
        </button>
      </div>
      <div class="row pb-4">
        <div class="col-md-12 mb-2">
          <div class="card shadow-full border-0">
            <div class="card-body">
              <div class="row text-white mb-3">
                <div class="col-xl-3 col-md-6 col-12">
                  <div class="info-box bg-b-blue d-flex align-items-center">
                    <span class="info-box-icon push-bottom"
                      ><i class="icon-users text-black font-40 m-0"></i
                    ></span>
                    <router-link to="/User" class="text-white">
                      <div class="info-box-content d-flex-column">
                      <div>
                        <span class="info-box-text">{{
                          language.AllUser
                        }}</span>
                      </div>
                      <div>
                        <span class="info-box-number">{{ allUser }}</span>
                      </div>
                    </div>
                    </router-link>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-xl-3 col-md-6 col-12">
                  <div class="info-box bg-b-green d-flex align-items-center">
                    <span class="info-box-icon push-bottom"
                      ><i class="icon-users text-black font-40 m-0"></i
                    ></span>
                    <router-link to="/User" class="text-white">
                    <div class="info-box-content">
                      <div>
                        <span class="info-box-text">{{
                          language.AdminAndEmployee
                        }}</span>
                      </div>
                      <div>
                        <span class="info-box-number">{{
                          adminAndEmployee
                        }}</span>
                      </div>
                    </div>
                    </router-link>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-xl-3 col-md-6 col-12">
                  <div class="info-box bg-b-yellow d-flex align-items-center">
                    <span class="info-box-icon push-bottom"
                      ><i class="icon-users text-black font-40 m-0"></i
                    ></span>
                    <router-link to="/Provider" class="text-white">
                    <div class="info-box-content">
                      <div>
                        <span class="info-box-text">{{
                          language.Provider
                        }}</span>
                      </div>
                      <div>
                        <span class="info-box-number">{{ provider }}</span>
                      </div>
                    </div>
                    </router-link>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-xl-3 col-md-6 col-12">
                  <div class="info-box bg-b-pink d-flex align-items-center">
                    <span class="info-box-icon push-bottom"
                      ><i class="icon-users text-black font-40 m-0"></i
                    ></span>
                    <router-link to="/User" class="text-white">
                    <div class="info-box-content">
                      <div>
                        <span class="info-box-text">{{
                          language.Customer
                        }}</span>
                      </div>
                      <div>
                        <span class="info-box-number">{{ user }}</span>
                      </div>
                    </div>
                    </router-link>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <!-- /.col -->
              </div>

              <div class="row text-white">
                <div class="col-xl-4 col-md-6 col-12">
                  <div class="info-box bg-b-blue d-flex align-items-center">
                    <span class="info-box-icon push-bottom"
                      ><i
                        class="icon-shopping-basket text-black font-40 m-0"
                      ></i
                    ></span>
                    <router-link to="/Prder" class="text-white">
                    <div class="info-box-content">
                      <div>
                        <span class="info-box-text">{{
                          language.AllOrder
                        }}</span>
                      </div>
                      <div>
                        <span class="info-box-number">{{ order }}</span>
                      </div>
                    </div>
                    </router-link>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-xl-4 col-md-6 col-12">
                  <div class="info-box bg-b-green d-flex align-items-center">
                    <span class="info-box-icon push-bottom"
                      ><i
                        class="icon-shopping-basket text-black font-40 m-0"
                      ></i
                    ></span>
                    
                    <div class="info-box-content">
                      <div>
                        <span class="info-box-text">{{
                          language.FreindlyOrder
                        }}</span>
                      </div>
                      <div>
                        <span class="info-box-number">{{ freindlyOrder }}</span>
                      </div>
                    </div>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-xl-4 col-md-6 col-12">
                  <div class="info-box bg-b-yellow d-flex align-items-center">
                    <span class="info-box-icon push-bottom"
                      ><i
                        class="icon-shopping-basket text-black font-40 m-0"
                      ></i
                    ></span>
                    <router-link to="/Order" class="text-white">
                    <div class="info-box-content">
                      <div>
                        <span class="info-box-text">{{
                          language.CheckoutOrder
                        }}</span>
                      </div>
                      <div>
                        <span class="info-box-number">{{ checkoutOrder }}</span>
                      </div>
                    </div>
                    </router-link>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <!-- /.col -->
                <div class="col-xl-3 col-md-4 col-12">
                  <div class="info-box bg-b-yellow d-flex align-items-center">
                    <span class="info-box-icon push-bottom"
                      ><i class="icon-dollar-sign text-black font-40 m-0"></i
                    ></span>
                    <router-link to="/Wallet" class="text-white">
                    <div class="info-box-content">
                      <div>
                        <span class="info-box-text">{{ language.allSum }}</span>
                      </div>
                      <div>
                        <span class="info-box-number">{{ allSum }}</span>
                      </div>
                    </div>
                    </router-link>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <!-- /.col -->
                <div class="col-xl-3 col-md-4 col-12">
                  <div class="info-box bg-b-blue d-flex align-items-center">
                    <span class="info-box-icon push-bottom"
                      ><i class="icon-dollar-sign text-black font-40 m-0"></i
                    ></span>
                    <router-link to="/Provider" class="text-white">
                    <div class="info-box-content">
                      <div>
                        <span class="info-box-text"
                          >{{ language.Quota }} {{ language.Provider }}</span
                        >
                      </div>
                      <div>
                        <span class="info-box-number">{{ providerSum }}</span>
                      </div>
                    </div>
                    </router-link>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <!-- /.col -->
                <div class="col-xl-3 col-md-4 col-12">
                  <div
                    class="
                      info-box
                      bg-b-green
                      d-flex
                      flex-column
                      align-items-center
                    "
                  >
                    <span class="info-box-icon push-bottom"
                      ><i class="icon-dollar-sign text-black font-40 m-0"></i
                    ></span>
                    <div class="info-box-content">
                      <div>
                        <span class="info-box-text">{{
                          language.siteProfit
                        }}</span>
                      </div>
                      <div>
                        <span class="info-box-number">{{ siteProfitSum }}</span>
                      </div>
                    </div>
                     
                    <div class="info-box-content">
                      <div>
                        <span class="info-box-text">
                          {{ language.ServeMenu }}
                        </span>
                      </div>
                      <div>
                        <span class="info-box-number">{{
                          siteProfitProviderMenuSum
                        }}</span>
                      </div>
                    </div>
                    <div class="info-box-content">
                      <div>
                        <span class="info-box-text">
                          {{ language.Freindly }}
                        </span>
                      </div>
                      <div>
                        <span class="info-box-number">{{
                          siteProfitFreindlyPackSum
                        }}</span>
                      </div>
                    </div>
                    <div class="info-box-content">
                      <div>
                        <span class="info-box-text">
                          {{ language.Order }}
                        </span>
                      </div>
                      <div>
                        <span class="info-box-number">{{
                          siteProfitOrderSum
                        }}</span>
                      </div>
                    </div>

                    <!-- /.info-box-content -->
                  </div>

                  <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <!-- /.col -->
                <div class="col-xl-3 col-md-4 col-12">
                  <div class="info-box bg-b-green d-flex align-items-center">
                    <span class="info-box-icon push-bottom"
                      ><i class="icon-dollar-sign text-black font-40 m-0"></i
                    ></span>
                    <div class="info-box-content">
                      <div>
                        <span class="info-box-text">
                          {{ language.Quota }} {{ language.Employee }}
                        </span>
                      </div>
                      <div>
                        <span class="info-box-number">{{
                          employeeProfitSum
                        }}</span>
                      </div>
                    </div>
                    <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
                </div>
                <!-- /.col -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import {
  Loading,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";
export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      allUser: "",
      user: "",
      provider: "",
      adminAndEmployee: "",
      order: "",
      freindlyOrder: "",
      checkoutOrder: "",
      startDate: "",
      endDate: "",
      allSum: 0,
      providerSum: 0,
      siteProfitSum: 0,
      siteProfitProviderMenuSum: 0,
      siteProfitFreindlyPackSum: 0,
      siteProfitOrderSum: 0,
      employeeProfitSum: 0,
    };
  },
  mounted() {
    if (this.isAdminRole) {
      this.LoadData();
    } else {
      this.$router.push("/OperatorDashboard");
    }
  },
  methods: {
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get(`/Dashboard/GetFinancialStatistic`, {
            params: {
              StartDate: this.startDate,
              EndDate: this.endDate,
            },
          })
          .then((res) => {
            if (res.data.isSuccess) {
              this.allSum = res.data.result.allSum;
              this.providerSum = res.data.result.providerSum;
              this.siteProfitSum = res.data.result.siteProfitSum;
              this.siteProfitProviderMenuSum =
                res.data.result.siteProfitProviderMenuSum;
              this.siteProfitFreindlyPackSum =
                res.data.result.siteProfitFreindlyPackSum;
              this.siteProfitOrderSum = res.data.result.siteProfitOrderSum;
              this.employeeProfitSum = res.data.result.employeeProfitSum;
              this.employeeProfitSum = res.data.result.employeeProfitSum;
            }
          });
        axios
          .get(`/Dashboard/GetStatistic`, {
            params: {
              StartDate: this.startDate,
              EndDate: this.endDate,
            },
          })
          .then((res) => {
            if (res.data.isSuccess) {
              Loading(0);

              this.allUser = res.data.result.allUser;
              (this.user = res.data.result.user),
                (this.provider = res.data.result.provider),
                (this.adminAndEmployee = res.data.result.adminAndEmployee),
                (this.order = res.data.result.order),
                (this.freindlyOrder = res.data.result.freindlyOrder),
                (this.checkoutOrder = res.data.result.checkoutOrder);
            }
          })

          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
    Search() {
      this.LoadData();
    },
  },
};
</script>