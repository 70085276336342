<template>
  <div class="row flex-div">
    <div class="col-md-12 flex-div pb-3 pt-3">
      <div class="card flex-div border-0 main-shadow">
        <div class="card-header bg-white">
          <div class="row">
            <div class="col-lg-10 col-12">
              <div class="form-inline">
                <div class="form-group ml-3 mb-3">
                  <input
                    id="TelNu"
                    class="form-control"
                    type="text"
                    :placeholder="language.Id"
                    v-model="SearchInput.id"
                    @change="Search()"
                  />
                </div>

                <div class="form-group ml-3 mb-3">
                  <input
                    id="UserName"
                    class="form-control"
                    type="text"
                    :placeholder="language.Username"
                    v-model="SearchInput.userName"
                    @change="Search()"
                  />
                </div>

                <div class="form-group ml-3 mb-3">
                  <select v-model="SearchInput.isCheckout" class="form-control">
                    <option selected :value="0">{{ language.All }}</option>
                    <option :value="1">{{ language.IsCheckout }}</option>
                    <option :value="2">{{ language.UnIsCheckout }}</option>
                  </select>
                </div>

                <div class="form-group mb-2">
                  <label class="mr-2">{{ language.StartDate }}</label>
                  <datepicker v-model="SearchInput.startDate"></datepicker>
                </div>
                <div class="form-group mx-sm-3 mb-2">
                  <label class="mr-2">{{ language.EndDate }}</label>
                  <datepicker v-model="SearchInput.endDate"></datepicker>
                </div>
                <button
                  id="BtnSearch"
                  @click="Search()"
                  type="button"
                  class="btn btn-primary mx-3 mb-3"
                >
                  <i class="icon-search"></i>{{ language.Search }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="pat" class="flex-div">
          <operatororder-list
            :Orders="list"
            v-if="HasList"
          ></operatororder-list>
          <div class="card-footer bg-white">
            <nav aria-label="Page navigation" class="float-left">
              <ul class="pagination pagination-sm">
                <li v-if="Pageid > 1" class="page-item">
                  <a
                    aria-label="Previous"
                    @click="PageChev(0)"
                    class="page-link"
                    ><span aria-hidden="true"
                      ><i class="icon-chevron-right m-0"></i></span
                  ></a>
                </li>

                <li
                  v-for="index in PageCount"
                  :key="index"
                  :class="{ active: Pageid == index }"
                  class="page-item"
                >
                  <a @click="Page(index)" class="page-link"
                    >{{ index }}<span class="sr-only"></span
                  ></a>
                </li>

                <li v-if="PageCount + 1 > Pageid" class="page-item">
                  <a aria-label="Next" @click="PageChev(1)" class="page-link"
                    ><span aria-hidden="true"
                      ><i class="icon-chevron-left m-0"></i></span
                  ></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import OperatororderList from "./OperatororderList.vue";
import axios from "axios";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import {
  Loading,
  TitleBar,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";

export default {
  components: {
    Datepicker,
    OperatororderList,
  },
  data() {
    return {
      PageCount: 1,
      Pageid: 1,
      list: [],
      HasList: false,
      SearchInput: {
        id: "",
        userName: "",
        providerShopName: "",
        isCheckout: 0,
        startDate: "",
        endDate: "",
        OperatorUserName: "",
      },
    };
  },
  mounted() {
    TitleBar(this.language.Order);
    this.LoadData();
  },
  methods: {
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get(`/Order/GetByCurrentEmployee`, {
            params: {
              PageId: this.Pageid,
              userId: null,
              employeeId: 0,
              id: this.SearchInput.id,
              userName: this.SearchInput.userName,
              isCheckout: this.SearchInput.isCheckout,
              startDate: this.SearchInput.startDate,
              endDate: this.SearchInput.endDate,
            },
          })
          .then((res) => {
            if (res.data.isSuccess) {
              if (res.data.result.list.length == 0) {
                this.HasList = false;
                Loading(0);
              } else {
                this.HasList = true;
                this.PageCount = res.data.result.pageCount;
                this.list = res.data.result.list;
                Loading(0);
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
    Search() {
      this.LoadData();
    },

    Page(id) {
      this.Pageid = id;
      this.LoadData();
    },
    PageChev(i) {
      if (i === 1) {
        this.Pageid++;
      } else if (i === 0) {
        this.Pageid = this.Pageid - 1;
      }
      this.LoadData();
    },
  },
};
</script>
  