<template>
  <div class="row flex-div">
    <div class="col-md-12 flex-div pb-3 pt-3">
      <div class="card flex-div border-0 main-shadow">
        <div class="card-header bg-white">
          <div class="row">
            <div class="col-lg-9 col-12">
              <div class="form-inline">
                <div class="form-group ml-3 mb-3">
                  <input
                    id="UserName"
                    class="form-control"
                    type="text"
                    :placeholder="language.ShopName"
                    v-model="SearchInput.ShopName"
                    @change="Search()"
                  />
                </div>
                <div class="form-group ml-3 mb-3">
                  <input
                    id="TelNu"
                    class="form-control"
                    type="text"
                    :placeholder="language.TelNu"
                    v-model="SearchInput.TelNu"
                    @change="Search()"
                  />
                </div>

                <div class="form-group ml-3 mb-3">
                  <select v-model="SearchInput.CategoryId" class="form-control">
                    <option :value="0">
                      {{ language.All }} {{ language.Category }}
                    </option>
                    <option
                      v-for="Category in CategoryList"
                      :key="Category.id"
                      :value="Category.id"
                    >
                      {{ Category.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group ml-3 mb-3">
                  <select
                    v-model="SearchInput.ProviderOrdering"
                    class="form-control"
                  >
                    <option :value="0">
                      {{ language.All }} {{ language.Amount }}
                    </option>
                    <option :value="1">
                      {{ language.IsCheckout }}
                    </option>
                    <option :value="2">{{ language.UnIsCheckout }}</option>
                  </select>
                </div>
                <button
                  id="BtnSearch"
                  @click="Search()"
                  type="button"
                  class="btn btn-primary mx-3 mb-3"
                >
                  <i class="icon-search"></i>{{ language.Search }}
                </button>
              </div>
            </div>
            <div class="col-md-3 col-3">
              <a
                @click="Create()"
                data-toggle="modal"
                data-target="#modalSave"
                class="btn btn-success mx-1 mb-1 float-lg-right float-none text-white Loader"
                ><i class="icon-plus"></i>{{ language.CreateProvider }}</a
              >
            </div>
          </div>
        </div>
        <div id="pat" class="flex-div">
          <div class="quotas-div">
            <div class="d-flex justify-content-center align-items-start">
              <div class="quota-div">
                <span class="quota-option">
                  {{ language.Sum }} {{ language.UnIsCheckout }}:</span
                >

                <span class="quota-value"> {{ AllSumUnCheckout }}</span>
              </div>
              <div class="quota-div">
                <span class="quota-option">
                  {{ language.Sum }} {{ language.Quota }}
                  {{ language.Site }}:</span
                >
                <span class="quota-value">
                  {{ AllSumUnCheckoutSite }} ({{ language.UnIsCheckout }})</span
                >
              </div>
              <div class="quota-div">
                <span class="quota-option">
                  {{ language.Sum }} {{ language.Quota }}
                  {{ language.Provider }}:</span
                >
                <span class="quota-value">
                  {{ AllSumUnCheckoutShop }} ({{ language.UnIsCheckout }})</span
                >
              </div>
            </div>
          </div>

          <provider-list :Providers="list" v-if="HasList"></provider-list>
          <div class="card-footer bg-white">
            <nav aria-label="Page navigation" class="float-left">
              <ul class="pagination pagination-sm">
                <li v-if="Pageid > 1" class="page-item">
                  <a
                    aria-label="Previous"
                    @click="PageChev(0)"
                    class="page-link"
                    ><span aria-hidden="true"
                      ><i class="icon-chevron-right m-0"></i></span
                  ></a>
                </li>

                <li
                  v-for="index in PageCount"
                  :key="index"
                  :class="{ active: Pageid == index }"
                  class="page-item"
                >
                  <a @click="Page(index)" class="page-link"
                    >{{ index }}<span class="sr-only"></span
                  ></a>
                </li>

                <li v-if="PageCount + 1 > Pageid" class="page-item">
                  <a aria-label="Next" @click="PageChev(1)" class="page-link"
                    ><span aria-hidden="true"
                      ><i class="icon-chevron-left m-0"></i></span
                  ></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProviderList from "./ProviderList.vue";
import axios from "axios";
import "@vuepic/vue-datepicker/dist/main.css";
import CreateFullProvider from "../provider/CreateFullProvider.vue";
import { openModal } from "jenesius-vue-modal";
import {
  Loading,
  TitleBar,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";

export default {
  components: {
    ProviderList,
  },
  data() {
    return {
      PageCount: 1,
      Pageid: 1,
      list: [],
      HasList: false,
      AllSumUnCheckout: "",
      AllSumUnCheckoutSite: "",
      AllSumUnCheckoutShop: "",
      CategoryList: [],
      SearchInput: {
        Id: 0,
        ShopName: null,
        TelNu: null,
        CategoryId: 0,
        ProviderOrdering: 0,
      },
    };
  },
  mounted() {
    TitleBar(this.language.Provider);
    this.LoadData();
  },
  methods: {
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get(`/Category/GetList`)
          .then((res) => {
            if (res.data.isSuccess) {
              if (res.data.result.list.length == 0) {
                this.HasList = false;
              } else {
                this.HasList = true;
                this.PageCount = res.data.result.pageCount;
                this.CategoryList = res.data.result.list.filter(
                  (l) => !l.isHotel || l.isHotel == null
                );
              }
            }
          })
          .catch((err) => console.log(err));

        axios
          .get(`/Provider/GetList`, {
            params: {
              PageId: this.Pageid,
              ShopName: this.SearchInput.ShopName,
              TelNu: this.SearchInput.TelNu,
              CategoryId: this.SearchInput.CategoryId,
              ProviderOrdering: this.SearchInput.ProviderOrdering,
            },
          })
          .then((res) => {
            if (res.data.isSuccess) {
              if (res.data.result.list.length == 0) {
                this.HasList = false;
                Loading(0);
              } else {
                this.HasList = true;
                this.PageCount = res.data.result.pageCount;
                this.list = res.data.result.list.filter(
                  (l) => !l.isHotel || l.isHotel == null
                );
                this.AllSumUnCheckout = res.data.result.allSumUnCheckout;
                this.AllSumUnCheckoutSite =
                  res.data.result.allSumUnCheckoutSite;
                this.AllSumUnCheckoutShop =
                  res.data.result.allSumUnCheckoutShop;
                Loading(0);
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
    Create() {
      openModal(CreateFullProvider, {
        IdProp: null,
        UserIdProp: 1, //fake prop
      });
    },
    Search() {
      this.LoadData();
    },

    Page(id) {
      this.Pageid = id;
      this.LoadData();
    },
    PageChev(i) {
      if (i === 1) {
        this.Pageid++;
      } else if (i === 0) {
        this.Pageid = this.Pageid - 1;
      }
      this.LoadData();
    },
  },
};
</script>
