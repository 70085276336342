<template>
  <div class="modal show">
    <div
      class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
      role="document"
    >
      <div id="modal-content" class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="modalCenterTitle">
            {{ language.Category }}
          </h6>
          <button
            @click="closeModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body flex-div">
          <div class="card categry-box flex-div">
            <div class="card-header row">
              <div class="col-xl-4 col-lg-10 col-sm-9 col-5">
                <input
                  class="form-control"
                  v-model="title"
                  :placeholder="language.faCategoryName"
                />
              </div>
              <div class="col-xl-4 col-lg-10 col-sm-9 col-5">
                <input
                  class="form-control"
                  v-model="trtitle"
                  :placeholder="language.TrCategoryName"
                />
              </div>
              <div class="col-xl-4 col-lg-2 col-sm-3 col-5">
                <span
                  @click="Submit()"
                  data-placement="top"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="ویرایش"
                >
                  <a onclick="Delete(@item.CategoryId)"
                    ><i class="btn icon-plus mr-2 text-success p-0"></i
                  ></a>
                </span>
              </div>
            </div>
            <div class="card-body flex p-0">
              <ul class="list-group list-group-flush pb-4">
                <li class="list-group-item" v-for="item in list" :key="item.id">
                  <div class="row">
                    <a class="col-xl-4 col-lg-10 col-sm-9 col-5">{{
                      item.name
                    }}</a>
                    <a class="col-xl-4 col-lg-10 col-sm-9 col-5">{{
                      item.trName
                    }}</a>
                    <div class="col-xl-4 col-lg-2 col-sm-3 col-5">
                      <div class="option d-flex">
                        <span
                          data-placement="top"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="ویرایش"
                        >
                          <a @click="Delete(item.id)"
                            ><i class="btn icon-trash mr-2 text-danger p-0"></i
                          ></a>
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="closeModal()"
            type="button"
            class="btn btn-sm btn-info"
            data-dismiss="modal"
          >
            {{ language.Close }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { closeModal } from "jenesius-vue-modal";
import { Loading, ShowAlert } from "../../../../public/js/Utilities.js";
import axios from "axios";
export default {
  data() {
    return {
      title: "",
      trtitle: "",
      list: [],
    };
  },
  methods: {
    closeModal() {
      closeModal();
    },
    loadData() {
      Loading(1);
      axios
        .get(
          `ProviderMenuCategory/GetByProviderIdList?ProviderId=${this.$route.params.Provider}`
        )
        .then((res) => {
          if (res.data.isSuccess) {
            this.list = res.data.result;
            Loading(0);
          }
        });
    },
    Submit() {
      Loading(1);
      if (this.title != null && this.title != "") {
        axios
          .post("ProviderMenuCategory/CreateOrEdit", {
            id: null,
            name: this.title,
            providerId: this.$route.params.Provider,
            trName: this.trtitle,
          })
          .then(() => {
            this.title = "";
            this.trtitle = "";
            ShowAlert("success", "", this.language.success);
            this.loadData();
          });
      } else {
        Loading(0);
        ShowAlert("danger", this.language.error, this.language.ValidateForm);
      }
    },
    Delete(id) {
      Loading(1);
      axios.post(`ProviderMenuCategory/Delete?ProviderMenuCategoryId=${id}`);
      setTimeout(() => {
        this.loadData();
      }, 500);
      ShowAlert("success", "", this.language.success);
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
