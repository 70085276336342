<template>
  <div id="card-table" class="card-body flex-div">
    <div class="table-responsive flex">
      <table class="table fixed">
        <thead class="thead-light">
          <tr>
            <th scope="col">{{language.Id}}</th>
            <th scope="col">{{language.ExpirationDay}}</th>
              <th scope="col">{{language.Amount}}</th>
            <th scope="col">{{language.operations}}</th>
          </tr>
        </thead>
        <tbody>
          <freindlyPack-item
            v-for="item in FreindlyPacks"
            :key="item.id"
            :FreindlyPack="item"
          ></freindlyPack-item>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import FreindlyPackItem from "./FreindlyPackItem.vue";

export default {
  props:["FreindlyPacks"],
  components: {
    FreindlyPackItem,
  },
 
};
</script>