<template>
  <div class="modal show">
    <div
      class="
        modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable
      "
      role="document"
    >
      <div id="modal-content" class="modal-content">
        <div class="modal-header">
          <div>
            <h5 class="modal-title">
              <span class="icon icon-arrow-up text-success"></span
              >{{ language.IncreaseWallet }}
            </h5>
          </div>
          <div>
            <button
              @click="closeModal()"
              type="button"
              class="close float-right"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-row">
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">{{
                    language.TotalAssets
                  }}</label>
                  <div class="form-group col-lg-3">
                    <input
                      readonly
                      type="text"
                      class="form-control border border-success"
                      v-model="TotalAssets"
                      :placeholder="language.TotalAssets"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">{{
                    language.Amount
                  }}</label>
                  <div class="form-group col-lg-6">
                    <input
                      type="number"
                      class="form-control"
                      v-model="amount"
                      :placeholder="language.Amount"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">{{
                    language.Description
                  }}</label>
                  <div class="form-group col-lg-6">
                    <textarea
                      v-model="text"
                      :placeholder="language.Description"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-between">
              <div>
                <button
                  @click="DeductionWalletModal()"
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span class="icon icon-sync"></span>
                  {{ language.DeductionWallet }}
                </button>
                <button @click="WalletHistory" class="btn btn-info mr-2">
                        <span class="icon icon-list"></span>
                  {{ language.History }}
                </button>
              </div>
              <div>
                <button
                  @click="submitIncreaseWallet()"
                  class="btn btn-success ml-2"
                >
                  {{ language.Submit }}
                </button>
                <button
                  @click="closeModal()"
                  type="button"
                  class="btn btn-info"
                  data-dismiss="modal"
                >
                  {{ language.Close }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { closeModal, openModal } from "jenesius-vue-modal";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import DeductionWallet from "./DeductionWallet.vue";
import {
  Loading,
  CheckNetwork,
  ShowAlert,
  ReloadList,
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      v$: useValidate(),
      amount: Number,
      text: "",
      userId: 0,
      TotalAssets: 0,
    };
  },
  props: {
    IdProp: Number,
  },
  validations() {
    return {
      amount: { required },
      text: { required },
    };
  },
  mounted() {
    this.userId = this.IdProp;
    Loading(1);
    axios.post(`/Wallet/TotalAssets?UserId=${this.userId}`).then((res) => {
      this.TotalAssets = res.data;
      Loading(0);
    });
  },
  methods: {
    closeModal() {
      closeModal();
    },
    DeductionWalletModal() {
      openModal(DeductionWallet, {
        IdProp: this.userId,
      });
    },
    submitIncreaseWallet() {
      Loading(1);
      this.v$.$validate().then((res) => {
        if (res) {
          if (CheckNetwork()) {
            axios
              .post("/Wallet/Increase", {
                userId: this.userId,
                amount: this.amount,
                text: this.text,
                walletEnum: 2,
                operatorId: 1,
              })
              .then((res) => {
                if (res.data == true) {
                  Loading(0);
                  closeModal();
                  ReloadList();
                  ShowAlert("success", "", this.language.success);
                } else {
                  Loading(0);
                  ShowAlert("danger", this.language.error, res.data.message);
                }
              })
              .catch((err) => console.log(err));
          } else {
            Loading(0);
            ShowAlert(
              "danger",
              this.language.error,
              this.language.Checkyourinternet
            );
          }
        } else {
          Loading(0);
          ShowAlert("danger", this.language.error, this.language.ValidateForm);
        }
      });
    },
    WalletHistory() {
      window.open(`/wallet/UserHistory/${this.IdProp}`);
    },
  },
};
</script>
