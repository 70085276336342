<template>
  <div id="card-table" class="card-body flex-div">
    <div class="table-responsive flex">
      <table class="table fixed">
        <thead class="thead-light">
          <tr>
            <th scope="col">{{ language.Id }}</th>
            <th scope="col">{{ language.FullName }}</th>
            <th scope="col">{{ language.Username }}</th>
            <th scope="col">{{ language.Creditor }}</th>
            <th scope="col">{{ language.Debtor }}</th>
            <th scope="col">{{ language.operations }}</th>
          </tr>
        </thead>
        <tbody>
          <operator-item
            v-for="item in Operators"
            :key="item.id"
            :Operator="item"
          ></operator-item>
        </tbody>
      </table>
    </div>
  </div>
</template>
  <script>
import OperatorItem from "./OperatorItem.vue";

export default {
  props: ["Operators"],
  components: {
    OperatorItem,
  },
};
</script>