<template>
  <div id="card-table" class="card-body flex-div">
    <div class="table-responsive flex">
      <table class="table fixed">
        <thead class="thead-light">
          <tr>
            <th scope="col">{{ language.Id }}</th>
            <th scope="col">{{ language.Username }}</th>
            <th scope="col">{{ language.ShopName }}</th>
            <th scope="col">{{ language.Amount }}</th>
            <th scope="col">{{ language.Quota }} {{ language.Site }}</th>
            <th scope="col">{{ language.Quota }} {{ language.Employee }} </th>
            <th scope="col">{{ language.DateCreate }}</th>
            <th scope="col">{{ language.GoDate }}</th>
            <th scope="col">{{ language.operations }}</th>
          </tr>
        </thead>
        <tbody>
          <order-item
            v-for="item in Orders"
            :key="item.id"
            :Order="item"
          ></order-item>
        </tbody>
      </table>
    </div>
  </div>
</template>
  <script>
import OrderItem from "./OrderItem.vue";

export default {
  props: ["Orders"],
  components: {
    OrderItem,
  },
};
</script>