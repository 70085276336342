<template>
    <div class="row flex-div">
        <div class="col-md-12 flex pb-3 pt-3">
            <div class="row pb-4">
                <div class="col-md-12 mb-2">
                    <div class="card shadow-full border-0">
                        <div class="card-body">

                            <div class="row text-white">
                                <div class="col-xl-4 col-md-6 col-12">
                                    <div class="info-box bg-b-blue d-flex align-items-center">
                                        <span class="info-box-icon push-bottom"><i
                                                class="icon-dollar-sign text-black font-40 m-0"></i></span>
                                        <div class="info-box-content">
                                            <div><span class="info-box-text">{{ language.Salary }}</span></div>
                                            <div><span class="info-box-number">{{ sumSalary }}</span></div>


                                        </div>
                                        <!-- /.info-box-content -->
                                    </div>
                                    <!-- /.info-box -->
                                </div>
                                <!-- /.col -->
                                <div class="col-xl-4 col-md-6 col-12">
                                    <div class="info-box bg-b-green d-flex align-items-center">
                                        <span class="info-box-icon push-bottom "><i
                                                class="icon-dollar-sign text-black font-40 m-0"></i></span>
                                        <div class="info-box-content">
                                            <div><span class="info-box-text">{{ language.Creditor }}</span></div>
                                            <div><span class="info-box-number">{{ sumCreditor }}</span></div>


                                        </div>
                                        <!-- /.info-box-content -->
                                    </div>
                                    <!-- /.info-box -->
                                </div>
                                <!-- /.col -->
                                <div class="col-xl-4 col-md-6 col-12">
                                    <div class="info-box bg-b-yellow d-flex align-items-center">
                                        <span class="info-box-icon push-bottom"><i
                                                class="icon-dollar-sign text-black font-40 m-0"></i></span>
                                        <div class="info-box-content">
                                            <div><span class="info-box-text">{{ language.Debtor }}</span></div>
                                            <div><span class="info-box-number">{{ sumDebtor }}</span></div>


                                        </div>
                                        <!-- /.info-box-content -->
                                    </div>
                                    <!-- /.info-box -->
                                </div>
                                <!-- /.col -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import axios from "axios";

import "@vuepic/vue-datepicker/dist/main.css";
import { Loading, CheckNetwork, ShowAlert } from "../../../../public/js/Utilities.js";
export default {

    data() {
        return {

            sumDebtor: 0,
            sumCreditor: 0,
            sumSalary: 0

        };
    },
    mounted() {

        this.LoadData();
    },
    methods: {
        LoadData() {
            Loading(1);
            if (CheckNetwork()) {
                axios
                    .get(`/Dashboard/GetOperatorStatistic`)
                    .then((res) => {
                        if (res.data.isSuccess) {

                            Loading(0);

                            this.sumDebtor = res.data.result.sumDebtor;
                            this.sumCreditor = res.data.result.sumCreditor;
                            this.sumSalary = res.data.result.sumSalary;
                           


                        }
                    })

                    .catch((err) => console.log(err));

            } else {
                Loading(0);
                ShowAlert(
                    "danger",
                    this.language.error,
                    this.language.Checkyourinternet
                );
            }
        },
        Search() {
            this.LoadData();
        },
    }
}
</script>