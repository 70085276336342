<template>
  <div class="row flex-div">
    <div class="col-md-12 flex-div pb-3 pt-3">
      <div class="card flex-div border-0 main-shadow">
        <div class="card-header bg-white">
          <div class="row">
            <div class="col-lg-10 col-12">
              <div class="form-inline">
                <div class="form-group ml-3 mb-3">
                  <input
                    id="UserName"
                    class="form-control"
                    type="text"
                    :placeholder="language.Username"
                    v-model="SearchInput.UserName"
                    @change="Search()"
                  />
                </div>
                <div class="form-group ml-3 mb-3">
                  <input
                    id="TelNu"
                    class="form-control"
                    type="text"
                    :placeholder="language.TelNu"
                    v-model="SearchInput.TelNu"
                    @change="Search()"
                  />
                </div>
                <div class="form-group ml-3 mb-3">
                  <input
                    id="Email"
                    class="form-control"
                    type="text"
                    :placeholder="language.Email"
                    v-model="SearchInput.Email"
                    @change="Search()"
                  />
                </div>
                <div class="form-group ml-3 mb-3">
                  <input
                    id="FullName"
                    class="form-control"
                    type="text"
                    :placeholder="language.FullName"
                    v-model="SearchInput.FullName"
                    @change="Search()"
                  />
                </div>
                <div class="form-group ml-3 mb-3">
                  <input
                    id="Email"
                    class="form-control"
                    type="text"
                    :placeholder="language.PassportNu"
                    v-model="SearchInput.PassportNu"
                    @change="Search()"
                  />
                </div>

                <div class="custom-control custom-checkbox ml-3 mb-3">
                  <input
                    id="IsProvider"
                    type="checkbox"
                    class="custom-control-input"
                    :placeholder="language.IsProvider"
                    v-model="SearchInput.IsProvider"
                    @change="Search()"
                  />
                  <label class="custom-control-label" for="IsProvider">{{
                    language.IsProvider
                  }}</label>
                </div>
                <div class="form-group ml-3 mb-3">
                  <input class="form-control"
                    v-model="SearchInput.BirthdayDate"
                    :placeholder="language.BirthdayDate"
                   />
                </div>

                <div class="form-group ml-3 mb-3">
                  <select v-model="SearchInput.Gender" class="form-control">
                    <option :selected="true" :value="0">
                      {{ language.All }}
                    </option>
                    <option :value="1">{{ language.Male }}</option>
                    <option :value="2">{{ language.Female }}</option>
                    <option :value="3">{{ language.Other }}</option>
                  </select>
                </div>
                <button
                  id="BtnSearch"
                  @click="Search()"
                  type="button"
                  class="btn btn-primary mx-3 mb-3"
                >
                  <i class="icon-search"></i>{{ language.Search }}
                </button>
              </div>
            </div>
            <div class="col-md-2 col-3">
              <a
                @click="Create()"
                data-toggle="modal"
                data-target="#modalSave"
                class="btn btn-success mx-1 mb-1 float-lg-right float-none text-white Loader"
                ><i class="icon-plus"></i>{{ language.Create }}</a
              >
            </div>
          </div>
        </div>
        <div id="pat" class="flex-div">
          <user-list :Users="list" v-if="HasList"></user-list>
          <div class="card-footer bg-white">
            <nav aria-label="Page navigation" class="float-left">
              <ul class="pagination pagination-sm">
                <li v-if="Pageid > 1" class="page-item">
                  <a
                    aria-label="Previous"
                    @click="PageChev(0)"
                    class="page-link"
                    ><span aria-hidden="true"
                      ><i class="icon-chevron-right m-0"></i></span
                  ></a>
                </li>

                <li
                  v-for="index in PageCount"
                  :key="index"
                  :class="{ active: Pageid == index }"
                  class="page-item"
                >
                  <a @click="Page(index)" class="page-link"
                    >{{ index }}<span class="sr-only"></span
                  ></a>
                </li>

                <li v-if="PageCount + 1 > Pageid" class="page-item">
                  <a aria-label="Next" @click="PageChev(1)" class="page-link"
                    ><span aria-hidden="true"
                      ><i class="icon-chevron-left m-0"></i></span
                  ></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserList from "./UserList.vue";
import axios from "axios";
import { openModal } from "jenesius-vue-modal";
import "@vuepic/vue-datepicker/dist/main.css";
import UserCreateOrEdit from "./UserCreateOrEdit.vue";
import {
  Loading,
  TitleBar,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";

export default {
  components: {
    UserList,
  },
  data() {
    return {
      PageCount: 1,
      Pageid: 1,
      list: [],
      HasList: false,
      SearchInput: {
        UserName: null,
        TelNu: null,
        Email: null,
        PassportNu: null,
        IsProvider: null,
        BirthdayDate: "",
        Gender: 0,
        ProviderId: 0,
        FullName: null,
      },
    };
  },
  mounted() {
    TitleBar(this.language.User);
    this.LoadData();
  },
  methods: {
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get(`/User/GetList`, {
            params: {
              PageId: this.Pageid,
              UserName: this.SearchInput.UserName,
              FullName: this.SearchInput.FullName,
              TelNu: this.SearchInput.TelNu,
              Email: this.SearchInput.Email,
              PassportNu: this.SearchInput.PassportNu,
              IsProvider: this.SearchInput.IsProvider,
              BirthdayDate: this.SearchInput.BirthdayDate,
              Gender: this.SearchInput.Gender,
            },
          })
          .then((res) => {
            if (res.data.isSuccess) {
              if (res.data.result.list.length == 0) {
                this.HasList = false;
                Loading(0);
              } else {
                this.HasList = true;
                this.PageCount = res.data.result.pageCount;
                this.list = res.data.result.list;
                Loading(0);
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
    Search() {
      this.LoadData();
    },
    Create() {
      openModal(UserCreateOrEdit, {
        idProp: 0,
        TelNuProp: "",
        EmailProp: "",
        PassportNuProp: "",
        PasswordProp: "",
        GenderProp: 0,
        WhatsappAccountProp: "",
        ResidenceProp: "",
        IsProviderProp: false,
        IsEmployeeProp: false,
      });
    },
    Page(id) {
      this.Pageid = id;
      this.LoadData();
    },
    PageChev(i) {
      if (i === 1) {
        this.Pageid++;
      } else if (i === 0) {
        this.Pageid = this.Pageid - 1;
      }
      this.LoadData();
    },
  },
};
</script>
