<template>
  <div class="row flex-div">
    <div class="col-md-12 flex-div pb-3 pt-3">
      <div class="card flex-div border-0 main-shadow">
        <div class="card-header bg-white">
          <div class="row">
            <div class="col-lg-10 col-12">
              <div class="form-inline">
                <div class="form-group ml-3">
                  <input
                    id="Title"
                    class="form-control"
                    type="text"
                    :placeholder="language.Title"
                    v-model="SearchName"
                    @change="Search()"
                  />
                </div>
                <button
                  id="BtnSearch"
                  @click="Search()"
                  type="button"
                  class="btn btn-primary mx-1 mb-1"
                >
                  <i class="icon-search"></i>{{ language.Search }}
                </button>
              </div>
            </div>
            <div class="col-md-2 col-3">
              <a
                @click="Create()"
                class="
                  btn btn-success
                  mx-1
                  mb-1
                  float-lg-right float-none
                  text-white
                  Loader
                "
                ><i class="icon-plus"></i>{{ language.Create }}</a
              >
            </div>
          </div>
        </div>
        <div id="pat" class="flex-div">
          <category-list :Categories="list" v-if="HasList"></category-list>
          <div class="card-footer bg-white">
            <nav aria-label="Page navigation" class="float-left">
              <ul class="pagination pagination-sm">
                <li v-if="Pageid > 1" class="page-item">
                  <a
                    aria-label="Previous"
                    @click="PageChev(0)"
                    class="page-link"
                    ><span aria-hidden="true"
                      ><i class="icon-chevron-right m-0"></i></span
                  ></a>
                </li>

                <li
                  v-for="index in PageCount"
                  :key="index"
                  :class="{ active: Pageid == index }"
                  class="page-item"
                >
                  <a @click="Page(index)" class="page-link"
                    >{{ index }}<span class="sr-only"></span
                  ></a>
                </li>

                <li v-if="PageCount + 1 > Pageid" class="page-item">
                  <a aria-label="Next" @click="PageChev(1)" class="page-link"
                    ><span aria-hidden="true"
                      ><i class="icon-chevron-left m-0"></i></span
                  ></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CategoryList from "./CategoryList.vue";
import axios from "axios";
import { openModal } from "jenesius-vue-modal";
import CategoryCreateOrEdit from "./CategoryCreateOrEdit.vue";
import {
  Loading,
  TitleBar,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";

export default {
  components: {
    CategoryList,
  },
  data() {
    return {
      PageCount: 1,
      Pageid: 1,
      list: [],
      HasList: false,
      SearchName: "",
    };
  },
  mounted() {
    TitleBar(this.language.Category);
    this.LoadData();
  },
  methods: {
    Search() {
      this.LoadData();
    },
    Create() {
      openModal(CategoryCreateOrEdit, {
        idProp: 0,
        titleProp: null,
        isReservetionProp: false,
      });
    },
    Page(id) {
      this.Pageid = id;
      this.LoadData();
    },
    PageChev(i) {
      if (i === 1) {
        this.Pageid++;
      } else if (i === 0) {
        this.Pageid = this.Pageid - 1;
      }
      this.LoadData();
    },
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get(`/Category/GetList`, {
            params: {
              PageId: this.Pageid,
              Name: this.SearchName,
            },
          })
          .then((res) => {
            if (res.data.isSuccess) {
              if (res.data.result.list.length == 0) {
                this.HasList = false;
                Loading(0);
              } else {
                this.HasList = true;
                this.PageCount = res.data.result.pageCount;
                this.list = res.data.result.list;
                Loading(0);
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
  },
};
</script>