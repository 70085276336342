<template>
  <button class="d-none" id="BtnSearch" @click="LoadData()"></button>
  <div class="row flex-div">
    <div class="col-md-12 flex pb-3 pt-3">
      <div class="card flex-div border-0 main-shadow">
        <div class="card-body flex-div">
          <div class="row flex-div flex-row">
            <div class="col-lg-4 col-12 flex-div">
              <div class="card shadow-full border-0 flex-div">
                <div class="card-header">
                  <h5 class="panel-box-title">
                    {{ language.ServeMenu }}
                    <i class="toggle-icon"></i>
                    <button
                      @click="CreateProviderMenu()"
                      class="btn btn-success btn-sm float-right"
                    >
                      <span class="icon-plus"></span>{{ language.Create }}
                    </button>
                    <button
                      @click="ProviderMenuCategory()"
                      class="btn btn-success btn-sm float-right ml-2"
                    >
                      <span class="icon-plus"></span>{{ language.Category }}
                    </button>
                  </h5>
                </div>

                <div id="card-table" class="card-body flex">
                  <div class="table-responsive flex">
                    <table class="table fixed">
                      <thead class="thead-light">
                        <tr>
                          <th scope="col">{{ language.image }}</th>
                          <th scope="col">{{ language.Name }}</th>
                          <th scope="col">{{ language.Amount }}</th>
                          <th scope="col">{{ language.operations }}</th>
                        </tr>
                      </thead>
                      <tbody v-if="HasListProviderMenuList">
                        <tr
                          :id="'ProviderMenu-' + ProviderMenu.id"
                          v-for="ProviderMenu in ProviderMenuList"
                          :key="ProviderMenu.id"
                        >
                          <td>
                            <img v-if="(ProviderMenu.providerSliderMenuImageUrls != null &&
                      ProviderMenu.providerSliderMenuImageUrls.
                    length > 0)"
                              style="height: 50px; width: 50px"
                              :src="ProviderMenu.providerSliderMenuImageUrls[0]"
                            />
                          </td>
                          <td>{{ ProviderMenu.name }}</td>
                          <td>{{ ProviderMenu.amount }}</td>
                          <td>
                            <span
                              v-on:dblclick="Delete(ProviderMenu.id)"
                              class="icon-trash text-danger"
                            ></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-8 col-12 flex-div">
              <div class="card shadow-full border-0 flex-div">
                <div class="card-header">
                  <h5 class="panel-box-title">
                    {{ language.ServeOrder }}
                    <i class="toggle-icon"></i>
                    <button
                      @click="BeforeProvideMenuCheckout"
                      class="btn btn-success btn-sm float-right"
                    >
                      <span class="icon-dollar-sign"></span
                      >{{ language.Checkouting }}
                    </button>
                  </h5>
                </div>

                <div id="card-table" class="card-body flex">
                  <div class="table-responsive flex">
                    <table class="table fixed">
                      <thead class="thead-light">
                        <tr>
                          <th scope="col">{{ language.Id }}</th>
                          <th scope="col">{{ language.Username }}</th>
                          <th scope="col">{{ language.Amount }}</th>
                          <th scope="col">{{ language.IsPayed }}</th>
                          <th scope="col">{{ language.IsDelivery }}</th>
                          <th scope="col">{{ language.DateCreate }}</th>
                          <th scope="col" class="bg-warning border">
                            {{ language.Item }}
                          </th>
                          <th scope="col" class="bg-warning border">
                            {{ language.DeskNumber }}
                          </th>
                          <th scope="col">
                            {{ language.IsCheckout }} {{ language.Site }}
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="HasListProviderMenuOrderList">
                        <tr
                          class="text-center"
                          :id="'ProviderMenuOrder-' + ProviderMenuOrder.id"
                          v-for="ProviderMenuOrder in ProviderMenuOrderList"
                          :key="ProviderMenuOrder.id"
                        >
                          <td>{{ ProviderMenuOrder.id }}</td>
                          <td>{{ ProviderMenuOrder.fullName }}</td>
                          <td>{{ ProviderMenuOrder.amount }}</td>
                          <td>
                            <input
                              @change="ChangeIsPayed(ProviderMenuOrder.id)"
                              type="checkbox"
                              v-model="ProviderMenuOrder.isPayed"
                            />
                          </td>
                          <td>
                            <input
                              @change="ChangeIsDelivery(ProviderMenuOrder.id)"
                              type="checkbox"
                              v-model="ProviderMenuOrder.isDelivery"
                            />
                          </td>
                          <td>{{ ProviderMenuOrder.createDate }}</td>
                          <td class="bg-warning border" v-html="ProviderMenuOrder.providerMenuName">
                          </td>
                          <td class="bg-warning border">
                            {{ ProviderMenuOrder.deskNumber }}
                          </td>
                          <td class="border">
                            <span
                              class="icon-check text-success"
                              v-if="ProviderMenuOrder.isCheckout"
                            ></span>
                            <span
                              class="icon-times text-warning"
                              v-if="!ProviderMenuOrder.isCheckout"
                            ></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer bg-white">
                  <nav aria-label="Page navigation" class="float-left">
                    <ul class="pagination pagination-sm">
                      <li v-if="Pageid > 1" class="page-item">
                        <a
                          aria-label="Previous"
                          @click="PageChev(0)"
                          class="page-link"
                          ><span aria-hidden="true"
                            ><i class="icon-chevron-right m-0"></i></span
                        ></a>
                      </li>

                      <li
                        v-for="index in PageCount"
                        :key="index"
                        :class="{ active: Pageid == index }"
                        class="page-item"
                      >
                        <a @click="Page(index)" class="page-link"
                          >{{ index }}<span class="sr-only"></span
                        ></a>
                      </li>

                      <li v-if="PageCount + 1 > Pageid" class="page-item">
                        <a
                          aria-label="Next"
                          @click="PageChev(1)"
                          class="page-link"
                          ><span aria-hidden="true"
                            ><i class="icon-chevron-left m-0"></i></span
                        ></a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { openModal } from "jenesius-vue-modal";
import CreateProviderMenu from "./CreateProviderMenu.vue";
import ProviderMenuCheckout from "./ProviderMenuCheckout.vue";
import ProviderMenuCategory from "./ProviderMenuCategory.vue";
import {
  Loading,
  TitleBar,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      PageCount: 1,
      Pageid: 1,
      HasListProviderMenuList: false,
      ProviderMenuList: [],
      HasListProviderMenuOrderList: false,
      ProviderMenuOrderList: [],
    };
  },
  mounted() {
    TitleBar(this.language.ServeMenu);
    this.LoadData();
  },
  methods: {
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get(`/Provider/GetById?id=${this.$route.params.Provider}`)
          .then((res) => {
            TitleBar(this.language.ServeMenu + " " + res.data.result.shopName);
            Loading(0);
          })
          .catch((err) => console.log(err));
        Loading(1);
        axios
          .get(
            `/ProviderMenu/GetByProviderList?id=${this.$route.params.Provider}`
          )
          .then((res) => {
            if (res.data.isSuccess) {
              console.log(res.data.result);
              this.HasListProviderMenuList = res.data.result.length > 0;
              this.ProviderMenuList = res.data.result;
              Loading(0);
            }
          })
          .catch((err) => console.log(err));
        Loading(1);
        axios
          .get(
            `/ProviderMenuOrder/GetlistByProviderId?PageId=${this.Pageid}&ProviderId=${this.$route.params.Provider}`
          )
          .then((res) => {
            if (res.data.isSuccess) {
              console.log(res.data.result.list);
              this.HasListProviderMenuOrderList =
                res.data.result.list.length > 0;
              this.ProviderMenuOrderList = res.data.result.list;
              Loading(0);
            }
          })
          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
    BeforeProvideMenuCheckout() {
      openModal(ProviderMenuCheckout, {
        IdProp: this.$route.params.Provider,
      });
    },
    Delete(id) {
      axios.post(`/ProviderMenu/DeleteById?id=${id}`);
      document.getElementById(`ProviderMenu-${id}`).remove();
      ShowAlert("danger", "", this.language.Deleted);
    },
    CreateProviderMenu() {
      openModal(CreateProviderMenu, {
        IdProp: this.$route.params.Provider,
      });
    },
    ProviderMenuCategory() {
      openModal(ProviderMenuCategory);
    },
    ChangeIsPayed(id) {
      axios.post(`/ProviderMenuOrder/ChangeIsPayed?id=${id}`);
    },
    ChangeIsDelivery(id) {
      axios.post(`/ProviderMenuOrder/ChangeIsDelivery?id=${id}`);
    },
    Page(id) {
      this.Pageid = id;
      this.LoadData();
    },
    PageChev(i) {
      if (i === 1) {
        this.Pageid++;
      } else if (i === 0) {
        this.Pageid = this.Pageid - 1;
      }
      this.LoadData();
    },
  },
};
</script>
