<template>
  <tr>
    <td>
      {{ FreindlyPack.id }}
    </td>
    <td>
      {{ FreindlyPack.expirationDay }}
    </td>
    <td>
      {{ FreindlyPack.amount }}
    </td>
    <td>
      <span
        data-placement="top"
        data-toggle="tooltip"
        title=""
        data-original-title="ویرایش"
      >
        <a @click="Edit()"
          ><i class="btn icon-edit mr-2 text-primary p-0 loader"></i
        ></a>
      </span>
    </td>
  </tr>
</template>
<script>
import { openModal } from "jenesius-vue-modal";
import FreindlyPackCreateOrEdit from "./FreindlyPackCreateOrEdit.vue";

export default {
  props: ["FreindlyPack"],
  methods: {
    Edit() {
      console.log(this.FreindlyPack);
      openModal(FreindlyPackCreateOrEdit, {
        idProp: this.FreindlyPack.id,
        expirationDayProp: this.FreindlyPack.expirationDay,
        AmountProp:this.FreindlyPack.amount
      });
    },
  },
};
</script>