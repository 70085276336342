<template>
  <div class="modal show">
    <div
      class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
      role="document"
    >
      <div id="modal-content" class="modal-content">
        <div class="modal-header">
          <h6 v-if="id == 0 || id == null" class="modal-title">
            {{ language.Create }}
          </h6>
          <h6 v-if="id != 0" class="modal-title">{{ language.Edit }}</h6>
          <button
            @click="closeModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-row">
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Category }}
                  </label>
                  <div class="form-group col-lg-6">
                    <select v-model="categoryId" class="form-control">
                      <option
                        v-for="Category in CategoryList"
                        :key="Category.id"
                        :value="Category.id"
                      >
                        {{ Category.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.LanguageFa }} {{ language.ShopName }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="shopName"
                      :placeholder="language.ShopName"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.LanguageTr }} {{ language.ShopName }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="TrshopName"
                      :placeholder="language.ShopName"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Star }}
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="number"
                      minlength="1"
                      maxlength="99"
                      class="form-control"
                      v-model="Star"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Quota }} (%)
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="number"
                      minlength="1"
                      maxlength="99"
                      class="form-control"
                      v-model="sitePercent"
                      :placeholder="language.QuotaSite - language.Quota"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Quota }} {{ language.ServeMenu }} (%)
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="number"
                      minlength="1"
                      maxlength="99"
                      class="form-control"
                      v-model="sitePercentMenuOrder"
                      :placeholder="language.QuotaSite - language.Quota"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Quota }} {{ language.Employee }} (%)
                  </label>
                  <div class="form-group col-lg-6">
                    <input
                      type="number"
                      minlength="1"
                      maxlength="99"
                      class="form-control"
                      v-model="operatorPercent"
                      :placeholder="language.Employee - language.Quota"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.Address }}
                  </label>
                  <div class="form-group col-lg-6">
                    <textarea
                      v-model="address"
                      :placeholder="language.Address"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.LanguageFa }} {{ language.Description }}
                  </label>
                  <div class="form-group col-lg-6">
                    <textarea
                      v-model="description"
                      :placeholder="language.Description"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">
                    {{ language.LanguageTr }} {{ language.Description }}
                  </label>
                  <div class="form-group col-lg-6">
                    <textarea
                      v-model="Trdescription"
                      :placeholder="language.Description"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="submitUser()"
            v-if="id == 0"
            class="btn btn-sm btn-success"
          >
            {{ language.Create }}
          </button>
          <button
            @click="submitUser()"
            v-if="id != 0"
            class="btn btn-sm btn-success"
          >
            {{ language.Edit }}
          </button>
          <button
            @click="closeModal()"
            type="button"
            class="btn btn-sm btn-info"
            data-dismiss="modal"
          >
            {{ language.Close }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { closeModal } from "jenesius-vue-modal";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  Loading,
  CheckNetwork,
  ShowAlert,
  ReloadList,
} from "../../../../public/js/Utilities.js";
export default {
  components: {},
  data() {
    return {
      v$: useValidate(),
      id: 0,
      categoryId: "",
      shopName: "",
      address: "",
      description: "",
      location: "",
      userId: 0,
      sitePercent: 1,
      sitePercentMenuOrder: 1,
      operatorPercent: 1,
      CategoryList: [],
      TrshopName: "",
      Trdescription: "",
      Star: "",
    };
  },
  validations() {
    return {
      categoryId: { required },
      address: { required },
      userId: { required },
      shopName: { required },
    };
  },

  props: {
    IdProp: Number,
    UserIdProp: Number,
  },
  mounted() {
    Loading(1);
    if (CheckNetwork()) {
      axios
        .get(`/Category/GetList`)
        .then((res) => {
          if (res.data.isSuccess) {
            if (res.data.result.list.length == 0) {
              this.HasList = false;
              Loading(0);
            } else {
              this.HasList = true;
              this.PageCount = res.data.result.pageCount;
              this.CategoryList = res.data.result.list;
              Loading(0);
            }
          }
        })
        .catch((err) => console.log(err));

      if (this.IdProp != null && this.IdProp != 0) {
        Loading(1);
        axios
          .get(`/Provider/GetById?id=${this.IdProp}`, {})
          .then((res) => {
            if (res.data.isSuccess) {
              this.categoryId = res.data.result.categoryId;
              this.shopName = res.data.result.shopName;
              this.address = res.data.result.address;
              this.userId = res.data.result.userId;
              this.id = res.data.result.id;
              this.sitePercent = res.data.result.sitePercent;
              this.description = res.data.result.description;
              this.sitePercentMenuOrder = res.data.result.sitePercentMenuOrder;
              this.operatorPercent = res.data.result.operatorPercent;
              this.TrshopName = res.data.result.trShopName;
              this.Trdescription = res.data.result.trDescription;
              this.Star = res.data.result.star;
              Loading(0);
            }
          })
          .catch((err) => console.log(err));
      }
      this.userId = this.UserIdProp;
    } else {
      Loading(0);
      ShowAlert("danger", this.language.error, this.language.Checkyourinternet);
    }
  },
  methods: {
    closeModal() {
      closeModal();
    },
    submitUser() {
      Loading(1);
      this.v$.$validate().then((res) => {
        if (res) {
          if (CheckNetwork()) {
            if (this.sitePercent > 99 || this.sitePercent < 1) {
              ShowAlert(
                "danger",
                this.language.error,
                this.language.ErrorofPercent
              );
              Loading(0);
              return;
            }
            axios
              .post("/Provider/CreateOrEditProvider", {
                id: this.id,
                categoryId: this.categoryId,
                categoryName: "-",
                shopName: this.shopName,
                address: this.address,
                location: "-",
                isReservesion: false,
                image: "-",
                menuImage: "-",
                userId: this.userId,
                sitePercent: this.sitePercent,
                description: this.description,
                sitePercentMenuOrder: this.sitePercentMenuOrder,
                operatorPercent: this.operatorPercent,
                trShopName: this.TrshopName,
                trDescription: this.Trdescription,
                Star: this.Star,
              })
              .then((res) => {
                if (res.data.isSuccess) {
                  Loading(0);
                  closeModal();
                  ReloadList();
                  ShowAlert("success", "✓", this.language.success);
                } else {
                  Loading(0);
                  ShowAlert("danger", this.language.error, res.data.message);
                }
              })
              .catch((err) => console.log(err));
          }
        } else {
          Loading(0);
          ShowAlert("danger", this.language.error, this.language.ValidateForm);
        }
      });
    },
  },
};
</script>
