<template>
  <div class="row flex-div">
    <div class="col-md-12 flex pb-3 pt-3">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-9">
          <div>
            <ul class="wizard-tab-list">
              <li
                v-for="(tab, index) in tabs"
                @click="ChangeCurrentTab(index)"
                class="wizard-item"
                :class="{ done: currentTab == index }"
                :key="index"
              >
                <a>{{ tab.title }}</a>
              </li>
            </ul>
          </div>

          <div class="wizard-contents">
            <div
              v-for="(tab, index) in tabs"
              :key="index"
              class="wizard-content"
              :class="{ 'active ': currentTab == index }"
              v-show="currentTab == index"
            >
              <div v-if="currentTab == 0">
                <div class="row flex-div">
                  <div class="col-md-12 flex-div pb-3 pt-3">
                    <div class="card flex-div border-0 main-shadow">
                      <div class="card-header bg-white">
                        <div class="row">
                          <div class="col-lg-10 col-12">
                            <div class="form-inline">
                              <div class="form-group ml-3 mb-3">
                                <input
                                  id="UserName"
                                  class="form-control"
                                  type="text"
                                  :placeholder="language.ShopName"
                                  v-model="SearchInput.ShopName"
                                  @change="Search()"
                                />
                              </div>
                              <div class="form-group ml-3 mb-3">
                                <input
                                  id="TelNu"
                                  class="form-control"
                                  type="text"
                                  :placeholder="language.TelNu"
                                  v-model="SearchInput.TelNu"
                                  @change="Search()"
                                />
                              </div>

                              <div class="form-group ml-3 mb-3">
                                <select
                                  v-model="SearchInput.CategoryId"
                                  class="form-control"
                                >
                                  <option :value="0">
                                    {{ language.All }} {{ language.Category }}
                                  </option>
                                  <option
                                    v-for="Category in CategoryList"
                                    :key="Category.id"
                                    :value="Category.id"
                                  >
                                    {{ Category.name }}
                                  </option>
                                </select>
                              </div>

                              <button
                                id="BtnSearch"
                                @click="Search()"
                                type="button"
                                class="btn btn-primary mx-3 mb-3"
                              >
                                <i class="icon-search"></i>{{ language.Search }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="pat" class="flex-div">
                        <div id="card-table" class="card-body flex-div">
                          <div class="table-responsive flex">
                            <table class="table fixed">
                              <thead class="thead-light">
                                <tr>
                                  <th scope="col">{{ language.Id }}</th>
                                  <th scope="col">{{ language.ShopName }}</th>
                                  <th scope="col">{{ language.Category }}</th>
                                  <th scope="col">
                                    {{ language.SelectProvider }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody v-if="HasList">
                                <tr
                                  v-for="Provider in Providerlist"
                                  :key="Provider.id"
                                >
                                  <td>
                                    {{ Provider.id }}
                                  </td>
                                  <td>
                                    {{ Provider.shopName }}
                                  </td>
                                  <td>
                                    {{ Provider.categoryName }}
                                  </td>

                                  <td>
                                    <input
                                      type="radio"
                                      name="customRadio"
                                      @change="
                                        SelectedProvider(
                                          Provider.id,
                                          Provider.shopName
                                        )
                                      "
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="card-footer bg-white">
                          <nav aria-label="Page navigation" class="float-left">
                            <ul class="pagination pagination-sm">
                              <li v-if="Pageid > 1" class="page-item">
                                <a
                                  aria-label="Previous"
                                  @click="PageChev(0)"
                                  class="page-link"
                                  ><span aria-hidden="true"
                                    ><i
                                      class="icon-chevron-right m-0"
                                    ></i></span
                                ></a>
                              </li>

                              <li
                                v-for="index in PageCount"
                                :key="index"
                                :class="{ active: Pageid == index }"
                                class="page-item"
                              >
                                <a @click="Page(index)" class="page-link"
                                  >{{ index }}<span class="sr-only"></span
                                ></a>
                              </li>

                              <li
                                v-if="PageCount + 1 > Pageid"
                                class="page-item"
                              >
                                <a
                                  aria-label="Next"
                                  @click="PageChev(1)"
                                  class="page-link"
                                  ><span aria-hidden="true"
                                    ><i class="icon-chevron-left m-0"></i></span
                                ></a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="currentTab == 1">
                <div class="row flex-div">
                  <div class="col-md-12 flex-div pb-3 pt-3">
                    <div class="card flex-div border-0 main-shadow">
                      <div class="card-header bg-white">
                        <div class="row">
                          <div class="col-lg-10 col-12">
                            <div class="form-inline"></div>
                          </div>
                        </div>
                      </div>
                      <div id="pat" class="flex-div">
                        <div id="card-table" class="card-body flex-div">
                          <div class="table-responsive flex">
                            <table class="table fixed">
                              <thead class="thead-light">
                                <tr>
                                  <th scope="col">
                                    {{ language.PersonCount }}
                                  </th>
                                  <th scope="col">
                                    {{ language.PricePersonCount }}
                                  </th>
                                  <th scope="col">{{ language.GoDate }}</th>
                                  <th scope="col">{{ language.SelectTime }}</th>
                                </tr>
                              </thead>
                              <tbody v-if="HasList">
                                <tr
                                  v-for="ProviderOptionValue in ProviderOptionValuelist"
                                  :key="ProviderOptionValue.id"
                                >
                                  <td>
                                    {{ ProviderOptionValue.id }}
                                  </td>
                                  <td>
                                    {{ ProviderOptionValue.pricePersonCount }}
                                  </td>
                                  <td>
                                    {{ ProviderOptionValue.viewGoDate }}
                                  </td>

                                  <td>
                                    <input
                                      type="radio"
                                      name="customRadio"
                                      @change="
                                        SelectedProviderOptionValue(
                                          ProviderOptionValue.id,
                                          ProviderOptionValue.viewGoDate
                                        )
                                      "
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="card-footer bg-white">
                          <nav aria-label="Page navigation" class="float-left">
                            <ul class="pagination pagination-sm">
                              <li v-if="Pageid > 1" class="page-item">
                                <a
                                  aria-label="Previous"
                                  @click="PageChev(0)"
                                  class="page-link"
                                  ><span aria-hidden="true"
                                    ><i
                                      class="icon-chevron-right m-0"
                                    ></i></span
                                ></a>
                              </li>

                              <li
                                v-for="index in PageCount"
                                :key="index"
                                :class="{ active: Pageid == index }"
                                class="page-item"
                              >
                                <a @click="Page(index)" class="page-link"
                                  >{{ index }}<span class="sr-only"></span
                                ></a>
                              </li>

                              <li
                                v-if="PageCount + 1 > Pageid"
                                class="page-item"
                              >
                                <a
                                  aria-label="Next"
                                  @click="PageChev(1)"
                                  class="page-link"
                                  ><span aria-hidden="true"
                                    ><i class="icon-chevron-left m-0"></i></span
                                ></a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="currentTab == 2">
                <div class="row flex-div">
                  <div class="col-md-12 flex-div pb-3 pt-3">
                    <div class="card flex-div border-0 main-shadow">
                      <div class="card-header bg-white"></div>
                      <div class="card-body">
                        <div>
                          <div class="form-group row">
                            <div class="col-md-6">
                              <div class="row">
                                <label class="col-sm-4 col-5 col-form-label">
                                  {{ language.ShopName }} :</label
                                >

                                <label class="col-sm-8 col-7 col-form-label">{{
                                  ProviderShopName
                                }}</label>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="row">
                                <label class="col-sm-4 col-5 col-form-label"
                                  >{{ language.SelectTime }} :</label
                                >

                                <label class="col-sm-8 col-7 col-form-label">{{
                                  ProviderOptionValueTime
                                }}</label>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="col-md-6">
                              <div class="row">
                                <label class="col-sm-4 col-form-label">{{
                                  language.SelectDay
                                }}</label>
                                <div class="col-sm-8">
                                  <datepicker
                                    v-model="Godate"
                                    format="yyyy/MM/dd"
                                    value-format="yyyy-MM-dd"
                                  >
                                  </datepicker>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="row">
                                <label class="col-sm-4 col-form-label">{{
                                  language.PersonCount
                                }}</label>
                                <div class="col-sm-8">
                                  <input
                                    id="PersonCount"
                                    class="form-control"
                                    type="number"
                                    :placeholder="language.PersonCount"
                                    v-model="PersonCount"
                                    @change="Search()"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <button
                          @click="SubmitReserve"
                          class="btn btn-  mx-3 float-left"
                        >
                          {{ language.Submit }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-pane fade"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import axios from "axios";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import {
  Loading,
  TitleBar,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      v$: useValidate(),
      currentTab: 0,
      lastTab: Number,
      route: useRoute(),

      UserId: null,
      ProviderOptionValueId: null,
      PersonCount: null,
      Godate: null,
      requestEnum: 0,

      PageCount: 1,
      Pageid: 1,

      Providerlist: [],
      ProviderOptionValuelist: [],

      ProviderShopName: null,
      ProviderOptionValueTime: null,

      HasList: false,
      SearchInput: {
        Id: 0,
        ShopName: null,
        TelNu: null,
        CategoryId: 0,
        ProviderOrdering: 0,
      },
      CategoryList: [],

      tabs: [
        {
          id: 0,
          title: this.language.SelectProvider,
        },
        {
          id: 1,
          title: this.language.SelectTime,
        },
        {
          id: 2,
          title: this.language.Final,
        },
      ],
    };
  },
  validations() {
    return {
      UserId: { required },
      ProviderOptionValueId: { required },
      PersonCount: { required },
      Godate: { required },
    };
  },
  mounted() {
    TitleBar(this.language.CreateOrder);
    this.lastTab = this.tabs.length;
    this.UserId = this.route.params.User;
    this.LoadData();
  },

  methods: {
    SelectedProvider(ProviderId, ProviderShopName) {
      this.ProviderId = ProviderId;
      this.ProviderShopName = ProviderShopName;
      Loading(1);
      this.currentTab = this.currentTab + 1;
      axios
        .get(`/ProviderOptionValue/GetByProviderIdList?id=${ProviderId}`)
        .then((res) => {
          this.ProviderOptionValuelist = res.data.result.filter(function (
            item
          ) {
            return item.isActive == true;
          });
          Loading(0);
        });
    },
    SelectedProviderOptionValue(
      ProviderOptionValueId,
      ProviderOptionValueTime
    ) {
      this.currentTab = this.currentTab + 1;
      this.ProviderOptionValueId = ProviderOptionValueId;
      this.ProviderOptionValueTime = ProviderOptionValueTime;
    },

    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get(`/Category/GetList`)
          .then((res) => {
            if (res.data.isSuccess) {
              if (res.data.result.list.length == 0) {
                this.HasList = false;
                Loading(0);
              } else {
                this.HasList = true;
                this.PageCount = res.data.result.pageCount;
                this.CategoryList = res.data.result.list;
                Loading(0);
              }
            }
          })
          .catch((err) => console.log(err));
        axios
          .get(`/Provider/GetList`, {
            params: {
              PageId: this.Pageid,
              ShopName: this.SearchInput.ShopName,
              TelNu: this.SearchInput.TelNu,
              CategoryId: this.SearchInput.CategoryId,
              ProviderIsOpen: 1,
            },
          })
          .then((res) => {
            if (res.data.isSuccess) {
              if (res.data.result.list.length == 0) {
                this.HasList = false;
                Loading(0);
              } else {
                this.HasList = true;
                this.PageCount = res.data.result.pageCount;
                this.Providerlist = res.data.result.list;
                Loading(0);
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
    Search() {
      this.LoadData();
    },
    ChangeCurrentTab(index) {
      if (index == 0) {
        this.ProviderId = null;
        this.currentTab = 0;
      } else if (index == 1) {
        if (this.ProviderId != null) {
          this.currentTab = 1;
          this.ProviderOptionValueId = null;
        }
      } else if (index == 2) {
        if (this.ProviderId != null && this.ProviderOptionValueId != null) {
          this.currentTab = 2;
        }
      }
    },
    Page(id) {
      this.Pageid = id;
      this.LoadData();
    },
    PageChev(i) {
      if (i === 1) {
        this.Pageid++;
      } else if (i === 0) {
        this.Pageid = this.Pageid - 1;
      }
      this.LoadData();
    },
    SubmitReserve() {
      Loading(1);
      this.v$.$validate().then((res) => {
        if (res) {
          if (CheckNetwork()) {
            axios
              .post("/Order/Create", {
                UserId: this.UserId,
                ProviderOptionValueId: this.ProviderOptionValueId,
                PersonCount: this.PersonCount,
                Godate: this.Godate,
                requestEnum: this.requestEnum,
              })
              .then((res) => {
                if (res.data.isSuccess) {
                  Loading(0);
                  ShowAlert("success", "", this.language.success);
                  this.$router.push({ path: `/Order` });
                } else {
                  Loading(0);
                  if (res.data.message === "IsExist") {
                    ShowAlert(
                      "danger",
                      this.language.error,
                      this.language.IsExistOrderError
                    );
                  }
                  if (res.data.message === "IsSuccessPersonCount") {
                    ShowAlert(
                      "danger",
                      this.language.error,
                      this.language.IsSuccessPersonCountError
                    );
                  }
                  if (res.data.message === "IsSufficientWallet") {
                    ShowAlert(
                      "danger",
                      this.language.error,
                      this.language.IsSufficientWalletError
                    );
                  }
                }
              });
          }
        } else {
          Loading(0);
          ShowAlert("danger", this.language.error, this.language.ValidateForm);
        }
      });
    },
  },
};
</script>