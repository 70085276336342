<template>
  <div id="card-table" class="card-body flex-div">
    <div class="table-responsive flex">
      <table class="table fixed">
        <thead class="thead-light">
          <tr>
            <th scope="col">{{ language.Id }}</th>
            <th scope="col">{{ language.ShopName }}</th>
            <th scope="col">{{ language.Category }}</th>
            <th scope="col">{{ language.Sum }} {{ language.UnIsCheckout }}</th>
            <th scope="col">{{ language.Quota }} {{ language.Site }}</th>
            <th scope="col">{{ language.Quota }} {{ language.Provider }}</th>
            <th scope="col">{{ language.operations }}</th>
          </tr>
        </thead>
        <tbody>
          <provider-item
            v-for="item in Providers"
            :key="item.id"
            :Provider="item"
          ></provider-item>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import ProviderItem from "./ProviderItem.vue";

export default {
  props: ["Providers"],
  components: {
    ProviderItem,
  },
};
</script>