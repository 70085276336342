import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Falanguagejson from "./language/fa.json"
import Trlanguagejson from "./language/tr.json"
import store from './store'
import "../public/scss/main.scss"
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import './axios';
// Main JS (in CommonJS format)
import VueTimepicker from 'vue3-timepicker'

// CSS 
import 'vue3-timepicker/dist/VueTimepicker.css'
let app = createApp(App);
app.config.globalProperties = {
    language: localStorage.getItem('language') == "tr" ? Trlanguagejson : Falanguagejson,
    isAdminRole: localStorage.getItem("Role") == "5XfnT5aksQ" ? true : false
}
app.provide('message', 'hello')
app.use(router);
app.use(store);

app.component('date-picker', Datepicker);
app.component('vue-timepicker', VueTimepicker);

app.mount('#app');


