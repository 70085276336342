<template>
  <div class="modal show">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
      <div id="modal-content" class="modal-content">
        <div class="modal-header">
          <h6 v-if="id == 0" class="modal-title">{{ language.Create }}</h6>
          <h6 v-if="id != 0" class="modal-title">{{ language.Edit }}</h6>
          <button
            @click="closeModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-row">
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">{{
                    language.ExpirationDay
                  }}</label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="expirationDay"
                      :placeholder="language.ExpirationDay"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <label class="col-lg-3 col-form-label">{{
                    language.Amount
                  }}</label>
                  <div class="form-group col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="amount"
                      :placeholder="language.Amount"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                @click="submitFreindlyPack()"
                v-if="id == 0"
                class="btn btn-sm btn-success"
              >
                {{ language.Create }}
              </button>
              <button
                @click="submitFreindlyPack()"
                v-if="id != 0"
                class="btn btn-sm btn-success"
              >
                {{ language.Edit }}
              </button>
              <button
                @click="closeModal()"
                type="button"
                class="btn btn-sm btn-info"
                data-dismiss="modal"
              >
                {{ language.Close }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { closeModal } from "jenesius-vue-modal";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  Loading,
  CheckNetwork,
  ShowAlert,
  ReloadList,
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      v$: useValidate(),
      id: Number,
      expirationDay: Date,
      amount:Number
    };
  },
  props: {
    idProp: Number,
    expirationDayProp: String,
    AmountProp:Number
  },
  validations() {
    return {
      expirationDay: { required },
      amount:{ required }
    };
  },
  methods: {
    closeModal() {
      closeModal();
    },
    submitFreindlyPack() {
      Loading(1);
      this.v$.$validate().then((res) => {
        if (res) {
          if (CheckNetwork()) {
            axios
              .post("/FreindlyPack/CreateOrEditFreindlyPack", {
                id: this.id,
                expirationDay: this.expirationDay,
                amount:this.amount
              })
              .then((res) => {
                if (res.data.isSuccess) {
                  Loading(0);
                  closeModal();
                  ReloadList();
                  ShowAlert("success", "", this.language.success);
                } else {
                  Loading(0);
                  ShowAlert("danger", this.language.error, res.data.message);
                }
              })
              .catch((err) => console.log(err));
          } else {
            Loading(0);
            ShowAlert(
              "danger",
              this.language.error,
              this.language.Checkyourinternet
            );
          }
        } else {
          Loading(0);
          ShowAlert("danger", this.language.error, this.language.ValidateForm);
        }
      });
    },
  },
  mounted() {
    this.id = this.idProp;
    this.expirationDay = this.expirationDayProp;
    this.amount=this.AmountProp;
  },
};
</script>
