<template>
  <div class="modal show">
    <div
      class="
        modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable
      "
      role="document"
    >
      <div id="modal-content" class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">{{ language.Edit }}</h6>
          <button
            @click="closeModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="file-upload">
            <div class="image-upload-wrap">
              <input
                class="file-upload-input"
                type="file"
                @change="readURL"
                accept="image/*"
              />
              <div class="drag-text">
                <img
                  v-if="PreviewImage == null"
                  src="../../../../public/assets/img/customupload.png"
                  alt=""
                  width="100"
                />
                <img
                  v-if="PreviewImage != null"
                  :src="PreviewImage"
                  alt=""
                  width="100"
                />
                <h3>Drag and drop a file or select add Image</h3>
              </div>
            </div>
            <div class="file-upload-content">
              <img class="file-upload-image" :src="ImageUrl" alt="your image" />
              <div class="image-title-wrap">
                <button type="button" class="remove-image">
                  Remove <span class="image-title">Uploaded Image</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="closeModal()"
            type="button"
            class="btn btn-sm btn-info"
            data-dismiss="modal"
          >
            {{ language.Close }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { closeModal } from "jenesius-vue-modal";
import {
  Loading,
  CheckNetwork,
  ShowAlert,
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      PreviewImage: null,
    };
  },
  props: {
    IdProp: Number,
  },
  mounted() {
    Loading(1);
    if (CheckNetwork()) {
      axios
        .get(`/Provider/GetImage?id=${this.IdProp}`)
        .then((res) => {
          if (res.data.isSuccess) {
            this.PreviewImage = res.data.result;
          }
          Loading(0);
        })
        .catch((err) => console.log(err));
    }
  },
  methods: {
    closeModal() {
      closeModal();
    },
    readURL(event) {
      var bodyFormData = new FormData();

        this.PreviewImage = URL.createObjectURL(event.target.files[0]);

      bodyFormData.append("Id", this.IdProp);
      bodyFormData.append("File", event.target.files[0]);
      bodyFormData.append("FolderName", "ProviderImage");

      axios
        .post("/Provider/UploadPrivderImage", bodyFormData, {
          "content-type": "multipart/form-data",
        })
        .then((res) => {
          if (!res.data.isSuccess) {
            ShowAlert("success", "", this.language.CheckExtensionFileError);
          }else{
             ShowAlert("success", "", this.language.success);
          }
        });
    },
  },
};
</script>


