<template>
  <div class="modal show">
    <div
      class="
        modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable
      "
      role="document"
    >
      <div id="modal-content" class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">
            {{ language.Checkouting }}
          </h6>
          <button
            @click="closeModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>{{ language.ProviderFullNameUserName }}:</label>
                  <label>{{ UserNameAndFullName }}</label>
                </div>
                <div class="form-group col-md-6">
                  <label>{{ language.Sum }} {{ language.UnIsCheckout }}:</label>
                  <label>{{ SumUnCheckout }}</label>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label
                    >{{ language.Quota }} {{ language.Site }}
                    {{ language.UnIsCheckout }}:</label
                  >
                  <label>{{ SumUnCheckoutSite }}</label>
                </div>
                <div class="form-group col-md-6">
                  <label>{{ language.Quota }} {{ language.Provider }}:</label>
                  <label>{{ SumPaymentShop }}</label>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>{{language.BankTrackingCode}}</label>
                  <textarea :disabled="SumPaymentShop==0"
                    class="form-control"
                    rows="5"
                    v-model="BankTrackingCode"
                  ></textarea>
                </div>
              </div>
              <button :disabled="SumPaymentShop==0"
                class="btn btn-success mb-2 float-right"
                @click="checkOut()"
              >
                {{ language.Checkouting }}
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { closeModal } from "jenesius-vue-modal";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  Loading,
  CheckNetwork,
  ShowAlert,
  ReloadList
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      v$: useValidate(),
      Id: 0,
      UserNameAndFullName: "string",
      SumUnCheckout: 0,
      SumUnCheckoutSite: 0,
      SumUnCheckoutShop: 0,
      SumPaymentShop: 0,
      BankTrackingCode: "",
    };
  },
  validations() {
    return {
      BankTrackingCode: { required },
    };
  },

  props: {
    IdProp: Number,
  },
  mounted() {
    this.LoadData();
  },
  methods: {
    Page(id) {
      this.Pageid = id;
      this.LoadData();
    },
    closeModal() {
      closeModal();
    },
    ChangeActive() {
      this.IsActive = !this.IsActive;
    },
    ChangeDisconnec() {
      this.isDisconnect = !this.isDisconnect;
    },
    checkOut() {
      console.log(this.BankTrackingCode);
      Loading(1);
      this.v$.$validate().then((res) => {
        if (res) {
          if (CheckNetwork()) {
            axios
              .post("/Checkout/CreateCheckout", {
                id: this.IdProp,
                bankTrackingCode: this.BankTrackingCode,
              })
              .then((res) => {
                if (res.data.isSuccess) {
                  Loading(0);
                  closeModal();
                  ShowAlert("success", "✓", this.language.success);
                  ReloadList();
                } else {
                  Loading(0);
                  ShowAlert("danger", this.language.error, res.data.message);
                }
              })
              .catch((err) => console.log(err));
          } else {
            Loading(0);
            ShowAlert(
              "danger",
              this.language.error,
              this.language.Checkyourinternet
            );
          }
        } else {
          Loading(0);
          ShowAlert("danger", this.language.error, this.language.ValidateForm);
        }
      });
    },
    LoadData() {
      Loading(1);
      if (CheckNetwork()) {
        axios
          .get(`/Checkout/BeforeCheckout?id=${this.IdProp}`)
          .then((res) => {
            if (res.data.isSuccess) {
              (this.UserNameAndFullName = res.data.result.userNameAndFullName),
                (this.SumUnCheckout = res.data.result.sumUnCheckout),
                (this.SumUnCheckoutSite = res.data.result.sumUnCheckoutSite),
                (this.SumUnCheckoutShop = res.data.result.sumUnCheckoutShop),
                (this.SumPaymentShop = res.data.result.sumPaymentShop),
                Loading(0);
            }
          })
          .catch((err) => console.log(err));
      } else {
        Loading(0);
        ShowAlert(
          "danger",
          this.language.error,
          this.language.Checkyourinternet
        );
      }
    },
  },
};
</script>