<template>
  <div class="modal show">
    <div
      class="
        modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable
      "
      role="document"
    >
      <div id="modal-content" class="modal-content">
        <div class="modal-header d-flex align-items-center">
          <h4 class="modal-title">{{ language.Order }}</h4>
          <button
            @click="closeModal()"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="order-info-item">
            <div class="row align-items-center">
              <div class="col-md-3">
                <h5 class="font-weight-bold m-0">{{ language.Id }}</h5>
              </div>
              <div class="col-md-9">
                {{ id }}
              </div>
            </div>
          </div>
          <div class="order-info-item">
            <div class="row align-items-center">
              <div class="col-md-3">
                <h5 class="font-weight-bold m-0">{{ language.Customer }}</h5>
              </div>
              <div class="col-md-9">
                {{ userName }}
              </div>
            </div>
          </div>
          <div class="order-info-item">
            <div class="row align-items-center">
              <div class="col-md-3">
                <h5 class="font-weight-bold m-0">{{ language.ShopName }}</h5>
              </div>
              <div class="col-md-9">
                {{ providerShopName }}
              </div>
            </div>
          </div>
          <div class="order-info-item">
            <div class="row align-items-center">
              <div class="col-md-3">
                <h5 class="font-weight-bold m-0">{{ language.PersonCount }}</h5>
              </div>
              <div class="col-md-9">
                {{ personCount }}
              </div>
            </div>
          </div>
          <div class="order-info-item">
            <div class="row align-items-center">
              <div class="col-md-3">
                <h5 class="font-weight-bold m-0">{{ language.Amount }}</h5>
              </div>
              <div class="col-md-9">
                {{ amount }}
              </div>
            </div>
          </div>
         
          <div class="order-info-item">
            <div class="row align-items-center">
              <div class="col-md-3">
                <h5 class="font-weight-bold m-0">{{ language.DateCreate }}</h5>
              </div>
              <div class="col-md-9">
                {{ dateCreate }}
              </div>
            </div>
          </div>
          <div class="order-info-item">
            <div class="row align-items-center">
              <div class="col-md-3">
                <h5 class="font-weight-bold m-0">{{ language.GoDate }}</h5>
              </div>
              <div class="col-md-9">
                {{ goDate }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="closeModal()"
            type="button"
            class="btn btn-sm btn-info"
            data-dismiss="modal"
          >
            {{ language.Close }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { closeModal } from "jenesius-vue-modal";
import {
  Loading,
  CheckNetwork,
  ShowAlert,
  //ReloadList,
} from "../../../../public/js/Utilities.js";
export default {
  data() {
    return {
      id: 0,
      userId: 0,
      userName: "",
      providerId: 0,
      providerShopName: "",
      amount: 0,
      isCheckout: false,
      dateCreate: "",
      goDate: "",
      personCount: 0,
    };
  },
  props: {
    idProp: Number,
  },
  mounted() {
    Loading(1);
    if (CheckNetwork()) {
      axios.get(`/Order/GetbyId?Id=${this.idProp}`).then((res) => {
        this.id = res.data.result.id;
        this.userId = res.data.result.userId;
        this.userName = res.data.result.userName;
        this.providerId = res.data.result.providerId;
        this.providerShopName = res.data.result.providerShopName;
        this.amount = res.data.result.amount;
        this.dateCreate = res.data.result.dateCreate;
        this.goDate = res.data.result.goDate;
        this.personCount = res.data.result.personCount;
      });
      Loading(0);
    } else {
      Loading(0);
      ShowAlert("danger", this.language.error, this.language.Checkyourinternet);
    }
  },
  methods: {
    closeModal() {
      closeModal();
    },
  },
};
</script>

 
  